// src/Elementary.js
import React, { useEffect, useState } from 'react';
import SyzygyBanner from '../../components/banner/SyzygyBanner';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import ElementaryData from './ElementaryData';
import './Elementary.css'; // Import the external CSS file

const ElementaryCourses = () => 
    {
        return (
            <>
                <SEO title="ElementaryCourses" />
                <Layout>
                    <SyzygyBanner />
                    <ElementaryData />
                </Layout>
            </>
        )
    }
  
export default ElementaryCourses;
