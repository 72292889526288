import React, { useState } from "react";

const Unit1CE3 = () => {
  const [recordedTexts, setRecordedTexts] = useState({});
  const [isRecording, setIsRecording] = useState({});
  const [recognitionInstances, setRecognitionInstances] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [scores, setScores] = useState(0);

  const questions = [
    {
      id: 1,
      question: "What's this?",
      imageUrl: "/images/exercise/book.png",
      options: ["A Pencil", "A Book", "A Ruler"],
      correctAnswer: "A Book.",
    },
    {
      id: 2,
      question: "What's this?",
      imageUrl: "/images/exercise/chair.png",
      options: ["A Blackboard", "A Chair", "A Desk"],
      correctAnswer: "A Chair.",
    },
    {
      id: 3,
      question: "What's that?",
      imageUrl: "/images/exercise/window.png",
      options: ["A Window", "A Door", "A Gate"],
      correctAnswer: "A Window.",
    },
  ];

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  const toggleRecording = (id) => {
    if (isRecording[id]) {
      stopRecording(id);
    } else {
      startRecording(id);
    }
  };

  const startRecording = (id) => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = "en-US";
    recognition.interimResults = true;

    // Clear previous text for the question
    setRecordedTexts((prev) => ({ ...prev, [id]: "" }));

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join(" ");
      setRecordedTexts((prev) => ({ ...prev, [id]: transcript }));
    };

    recognition.onerror = () => {
      alert("Error with speech recognition. Please try again.");
      stopRecording(id);
    };

    recognition.onend = () => {
      setIsRecording((prev) => ({ ...prev, [id]: false }));
    };

    if (recognitionInstances[id]) {
      recognitionInstances[id].abort(); // Safely abort previous instance
    }

    setRecognitionInstances((prev) => ({ ...prev, [id]: recognition }));
    recognition.start();
    setIsRecording((prev) => ({ ...prev, [id]: true }));
  };

  const stopRecording = (id) => {
    if (recognitionInstances[id]) {
      recognitionInstances[id].stop();
    }
    setIsRecording((prev) => ({ ...prev, [id]: false }));
  };

  const handleSubmit = () => {
    let score = 0;
    questions.forEach((question) => {
      const recordedAnswer = (recordedTexts[question.id] || "").trim().toLowerCase();
      if (recordedAnswer === question.correctAnswer.toLowerCase()) {
        score++;
      }
    });
    setScores(score);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h4 style={{ textAlign: "center", marginBottom: "30px" }}>Things in the Classroom</h4>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {questions.map((question) => (
          <div
            key={question.id}
            style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4>{question.question}</h4>
            <img
              src={question.imageUrl}
              alt="Question Object"
              style={{ maxWidth: "50%", marginBottom: "10px" }}
            />
            <ul style={{ listStyle: "none", padding: "0", marginBottom: "10px" }}>
              {question.options.map((option, idx) => (
                <li key={idx} style={{ marginBottom: "5px" }}>
                  <button
                    style={{
                      padding: "10px 20px",
                      fontSize: "14px",
                      cursor: "pointer",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                    }}
                    onClick={() => speakText(option)}
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
            <div>
              <button
                onClick={() => toggleRecording(question.id)}
                style={{
                  padding: "10px",
                  backgroundColor: isRecording[question.id] ? "red" : "#28a745",
                  color: "white",
                  fontSize: "14px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {isRecording[question.id] ? "Stop Recording" : "Start Recording"}
              </button>
            </div>
            <div
              style={{
                marginTop: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                color: "#555",
                fontSize: "14px",
              }}
            >
              {recordedTexts[question.id] || "Text will appear here"}
            </div>
          </div>
        ))}
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handleSubmit}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Submit Answers
        </button>
      </div>

      {/* Modal for displaying results */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "300px",
              textAlign: "center",
            }}
          >
            <h3 style={{ color: "#007BFF" }}>Results</h3>
            <p>Your Score: {scores} / {questions.length}</p>
            <button
              onClick={handleCloseModal}
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#28a745",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Unit1CE3;
