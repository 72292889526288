import React, { useState } from "react";

// Sample phrases and correct answers
const phrases = [
  "Open your books, please.",
  "Go to page eighty-four.",
  "Do exercise a.",
  "Read the text.",
  "Look at the board.",
  "Close the door.",
  "Work in pairs.",
  "Answer the questions.",
  "Listen and repeat.",
  "Stand up.",
  "Sit down.",
  "Turn off your phone.",
  "Sorry, can you repeat that?",
  "Sorry I’m late.",
  "I don’t understand.",
  "Can I have a copy, please?",
  "How do you spell it?",
  "I don’t know.",
  "How do you say gato in English?",
  "Can you help me, please?",
  "What page is it?",
  "Please stop talking!",
];

// Correct answers based on the provided answers
const correctAnswers = [
  7, 11, 13, 5, 1, 12, 2, 10, 9, 6, 3, 8, 19, 14, 18, 15, 22,
  17, 21, 20, 16, 4,
];

// Image paths and names for speech
const imagePaths = [
  { src: "/images/exercise/look-at-the-board-1.png", name: "Look at the board" },
  { src: "/images/exercise/work-in-pairs-2.png", name: "Work in pairs" },
  { src: "/images/exercise/sit-down-3.png", name: "Sit down" },
  { src: "/images/exercise/please-stop-talking-4.png", name: "Please stop talking" },
  { src: "/images/exercise/read-the-text-5.png", name: "Read the text" },
  { src: "/images/exercise/stand-up-6.png", name: "Stand up" },
  { src: "/images/exercise/open-your-books-please-7.png", name: "Open your books, please" },
  { src: "/images/exercise/turn-off-your-phone-8.png", name: "Turn off your phone" },
  { src: "/images/exercise/listen-and-repeat-9.png", name: "Listen and repeat" },
  { src: "/images/exercise/answer-the-questions-10.png", name: "Answer the questions" },
  { src: "/images/exercise/go-to-page-eighty-four-11.png", name: "Go to page eighty-four" },
  { src: "/images/exercise/close-the-door-12.png", name: "Close the door" },
  { src: "/images/exercise/do-exercise-a-13.png", name: "Do Exercise" },
  { src: "/images/exercise/sorry-im-late-14.png", name: "Sorry, I'm late" },
  { src: "/images/exercise/can-i-have-a-copy-please-15.png", name: "Can I have a copy, please" },
  { src: "/images/exercise/what-page-is-it-16.png", name: "What page is it" },
  { src: "/images/exercise/i-dont-know-17.png", name: "I don't know" },
  { src: "/images/exercise/i-dont-understand-18.png", name: "I don't understand" },
  { src: "/images/exercise/sorry-can-you-repeat-that-please-19.png", name: "Sorry, can you repeat that" },
  { src: "/images/exercise/can-you-help-me-please-20.png", name: "Can you help me, please" },
  { src: "/images/exercise/how-do-you-say-gato-in-english-21.png", name: "How do you say gato in English" },
  { src: "/images/exercise/how-do-you-spell-it-22.png", name: "How do you spell it" }
];

const Unit1CE4 = () => {
  const [userAnswers, setUserAnswers] = useState(Array(phrases.length).fill("")); // Initialize with empty strings
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [score, setScore] = useState(0);

  const handleChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newAnswers = [...userAnswers];
      newAnswers[index] = value;
      setUserAnswers(newAnswers);
    }
  };

  const handleImageClick = (name) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(name); // Speak image name
      utterance.lang = "en-US"; // Set language, adjust if needed
      utterance.pitch = 1; // Standard pitch
      utterance.rate = 1; // Standard rate
      utterance.volume = 1; // Full volume
      speechSynthesis.cancel(); // Stop any ongoing speech before starting new
      speechSynthesis.speak(utterance);
    } else {
      alert("Speech synthesis is not supported in your browser.");
    }
  };

  const handleSubmit = () => {
    let correctCount = 0;
    const newAnswers = [...userAnswers];
    userAnswers.forEach((answer, index) => {
      if (parseInt(answer) === correctAnswers[index]) {
        correctCount++;
        newAnswers[index] = answer; // keep it as a number
      } else {
        newAnswers[index] = answer; // wrong answer remains
      }
    });
    setUserAnswers(newAnswers);
    setScore(correctCount);
    setSubmitted(true);
    setShowModal(true);
  };

  const calculatePercentage = () => {
    return ((score / correctAnswers.length) * 100).toFixed(2);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const rows = Math.ceil(imagePaths.length / 3); // Calculate the number of rows based on image count

  return (
    <div style={{ display: "flex", maxWidth: "1200px", margin: "0 auto", padding: "10px" }}>
      {/* Left Side - Image Grid */}
      <div style={{ flex: 1, paddingRight: "5px" }}>
        <h5 style={{ textAlign: "center", marginBottom: "10px" }}>Click on an Image to Listen</h5>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* Dynamically rendering rows */}
          {[...Array(rows)].map((_, rowIndex) => (
            <div key={rowIndex} style={{ display: "flex", justifyContent: "center", marginBottom: "8px" }}>
              {imagePaths.slice(rowIndex * 3, rowIndex * 3 + 3).map((img, index) => (
                <img
                  key={index + rowIndex * 3}
                  src={img.src}
                  alt={img.name} // Keep alt for accessibility purposes
                  onClick={() => handleImageClick(img.name)}
                  style={{
                    width: "90px",
                    height: "75px",
                    margin: "10px",
                    borderRadius: "4px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Right Side - Quiz */}
      <div style={{ flex: 1, paddingLeft: "5px" }}>
        <h2 style={{ textAlign: "center", fontSize: "18px", margin: "10px 0" }}>Fill in the Numbers</h2>
        {phrases.map((phrase, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
            <input
              type="text"
              value={userAnswers[index]} // Use the value directly as a string
              onChange={(e) => handleChange(index, e.target.value)}
              placeholder=" "
              style={{
                width: "30px",
                height: "30px",
                marginRight: "8px",
                textAlign: "center",
                fontSize: "12px",
                color: "#000",
                backgroundColor: submitted
                  ? (parseInt(userAnswers[index]) === correctAnswers[index] ? "#d4f5d4" : "#f8d7da")
                  : "#fff",
                border: "1px solid #007BFF",
                borderRadius: "4px",
                padding: "5px",
              }}
            />
            <span style={{ flex: 1, fontSize: "12px", color: "#333" }}>{phrase}</span>
          </div>
        ))}
        <button
          onClick={handleSubmit}
          style={{
            width: "100%",
            padding: "8px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "10px",
            fontSize: "14px",
          }}
        >
          Submit Answers
        </button>

        {/* Modal for displaying results */}
        {showModal && (
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              <h3 style={{ color: "#007BFF" }}>Results</h3>
              <p>Correct: {score} / {correctAnswers.length}</p>
              <p>Percentage: {calculatePercentage()}%</p>
              <button onClick={handleCloseModal} style={modalStyles.closeButton}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Styles for the modal
const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    maxWidth: "400px",
    width: "90%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
  },
  closeButton: {
    marginTop: "10px",
    padding: "8px 12px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default Unit1CE4;
