import React, { useState } from "react";

// Helper function to map numeric characters to words
const convertNumbersToWords = (text) => {
  const numberMap = {
    0: "zero",
    1: "one",
    2.: "Two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
  };

  // Replace all numeric characters in the string with their word equivalent
  return text.replace(/\d/g, (match) => numberMap[match]);
};

const Unit1CE5 = () => {
  const [recordedTexts, setRecordedTexts] = useState({});
  const [isRecording, setIsRecording] = useState({});
  const [recognitionInstances, setRecognitionInstances] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [scores, setScores] = useState(0);

  const sections = [
    {
      image: "/images/exercise/listen-to-the-words-and-repeat-1.png",
      text: "phone: ‘O’",
      highlight: "O",
      words: ["Close", "Go", "Poland"],
    },
    {
      image: "/images/exercise/listen-to-the-words-and-repeat-2.png",
      text: "boot: ‘oo’",
      highlight: "o",
      words: ["School", "Do", "Two."],  // Adjusted words to be spelled out
    },
    {
      image: "/images/exercise/listen-to-the-words-and-repeat-3.png",
      text: "car: ‘aa’",
      highlight: "a",
      words: ["Class", "Answer", "France"],
    },
  ];

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  const toggleRecording = (id) => {
    if (isRecording[id]) {
      stopRecording(id);
    } else {
      startRecording(id);
    }
  };

  const startRecording = (id) => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = "en-US";
    recognition.interimResults = true;

    setRecordedTexts((prev) => ({ ...prev, [id]: "" }));

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join(" ");
      // Convert numbers in speech to words
      const convertedTranscript = convertNumbersToWords(transcript);
      setRecordedTexts((prev) => ({ ...prev, [id]: convertedTranscript }));
    };

    recognition.onerror = () => {
      alert("Error with speech recognition. Please try again.");
      stopRecording(id);
    };

    recognition.onend = () => {
      setIsRecording((prev) => ({ ...prev, [id]: false }));
    };

    if (recognitionInstances[id]) {
      recognitionInstances[id].abort();
    }

    setRecognitionInstances((prev) => ({ ...prev, [id]: recognition }));
    recognition.start();
    setIsRecording((prev) => ({ ...prev, [id]: true }));
  };

  const stopRecording = (id) => {
    if (recognitionInstances[id]) {
      recognitionInstances[id].stop();
    }
    setIsRecording((prev) => ({ ...prev, [id]: false }));
  };

  const handleSubmit = () => {
    let score = 0;
    let totalWords = 0;

    sections.forEach((section, sectionIndex) => {
      section.words.forEach((word, wordIndex) => {
        const wordId = `${sectionIndex}-${wordIndex}`;
        const recordedAnswer = (recordedTexts[wordId] || "").trim().toLowerCase();
        const correctWord = word.toLowerCase();

        // Check if the recorded word matches the correct word
        if (recordedAnswer === correctWord) {
          score++;
        }

        totalWords++;
      });
    });

    setScores(score);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div style={styles.unitContainer}>
      <h4 style={styles.title}>Listen to the Words and Repeat</h4>
      <div style={styles.sectionsContainer}>
        {sections.map((section, index) => (
          <div key={index} style={styles.sectionCard}>
            <div style={styles.sectionHeader}>
              <img src={section.image} alt="Exercise Section" style={styles.sectionImage} />
              <div>
                <h5>{section.text}</h5>
                <p><span style={styles.highlight}>{section.highlight}</span></p>
              </div>
            </div>

            <div style={styles.wordsContainer}>
              {section.words.map((word, wordIndex) => {
                const wordId = `${index}-${wordIndex}`;
                return (
                  <div key={wordIndex} style={styles.wordItem}>
                    <button style={styles.speakButton} onClick={() => speakText(word)}>
                      {word}
                    </button>
                    <div style={styles.recordedText}>
                      {recordedTexts[wordId] || "Text will appear here"}
                    </div>
                    <button
                      style={isRecording[wordId] ? { ...styles.recordButton, ...styles.recording } : styles.recordButton}
                      onClick={() => toggleRecording(wordId)}
                    >
                      {isRecording[wordId] ? "Stop Recording" : "Start Recording"}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>

      <div style={styles.submitContainer}>
        <button style={styles.submitButton} onClick={handleSubmit}>Submit Answers</button>
      </div>

      {showModal && (
        <div style={styles.modalContainer}>
          <div style={styles.modalContent}>
            <h3>Results</h3>
            <p>Your Score: {scores} / 9</p>
            <button style={styles.closeModal} onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  unitContainer: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
  },
  title: {
    textAlign: "center",
    marginBottom: "30px",
    fontSize: "24px",
    color: "#333",
  },
  sectionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "20px",
    marginBottom: "20px",
  },
  sectionCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "30px",
    textAlign: "center",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    width: "300px",
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  sectionImage: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
  highlight: {
    fontWeight: "bold",
    color: "#007bff",
  },
  wordsContainer: {
    marginBottom: "15px",
  },
  wordItem: {
    marginBottom: "15px",
  },
  speakButton: {
    padding: "10px 20px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  recordedText: {
    marginTop: "10px",
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: "4px",
    padding: "10px",
    backgroundColor: "rgb(249, 249, 249)", 
    color: "rgb(85, 85, 85)", 
    fontSize: "14px",
  },
  recordButton: {
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#28a745",
    color: "white",
    fontSize: "14px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: "100%",
  },
  recording: {
    backgroundColor: "red",
  },
  submitContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
  submitButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  modalContainer: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "300px",
    textAlign: "center",
  },
  closeModal: {
    marginTop: "10px",
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#28a745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default Unit1CE5;
