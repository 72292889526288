import React, { useState } from 'react';

const Unit1CE9 = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    email: '',
    mobile: '',
    age: '',
    landline: '',
    country: '',
    city: '',
    address: '',
    postcode: '',
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate form fields
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = true;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Reset errors and show success message
      setErrors({});
      setSubmitted(true);
      // Optionally, you can reset form data after submission
      setFormData({
        firstName: '',
        surname: '',
        email: '',
        mobile: '',
        age: '',
        landline: '',
        country: '',
        city: '',
        address: '',
        postcode: '',
      });
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: 'auto' }}>
      <h4 style={{ textAlign: 'center', marginBottom: '20px' }}> </h4>

      <h3 style={{ marginBottom: '15px' }}>Student Information</h3>

      {submitted && <div style={{ color: 'green', textAlign: 'center', marginBottom: '20px' }}>Form submitted successfully!</div>}

      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {/* First Row */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="firstName">First Name:</label>
            <input
              id="firstName"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.firstName ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="email">Email Address:</label>
            <input
              id="email"
              type="email"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.email ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Second Row */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="surname">Surname:</label>
            <input
              id="surname"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.surname ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.surname}
              onChange={handleChange}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="mobile">Phone (Mobile):</label>
            <input
              id="mobile"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.mobile ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Third Row */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="age">Age:</label>
            <input
              id="age"
              type="number"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.age ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.age}
              onChange={handleChange}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="landline">Phone (Landline):</label>
            <input
              id="landline"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.landline ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.landline}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Fourth Row */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="country">Country:</label>
            <input
              id="country"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.country ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.country}
              onChange={handleChange}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="city">City:</label>
            <input
              id="city"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.city ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.city}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Fifth Row */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="address">Address:</label>
            <input
              id="address"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.address ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="postcode">Postcode:</label>
            <input
              id="postcode"
              type="text"
              style={{
                width: '200px',
                padding: '5px',
                marginTop: '5px',
                border: errors.postcode ? '1px solid red' : '1px solid #ccc',
              }}
              value={formData.postcode}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Submit Button */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            type="submit"
            style={{
              padding: '10px 30px',
              fontSize: '1.5rem',
              backgroundColor: '#007BFF',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Unit1CE9;
