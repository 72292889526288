import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

// Font Awesome for social icons
import { FaFacebook, FaLinkedin, FaInstagram, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer style={footerStyle}>
        <div style={footerWrapperStyle}>
            {/* Social Media Icons Section */}
            <div style={socialSectionStyle}>
                <h3 style={socialTitleStyle}>Follow Us</h3>
                <div style={socialIconsStyle}>
                    <a href="https://www.facebook.com/profile.php?id=100090036609090" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
                        <FaFacebook size={30} color="#3b5998" />
                    </a>
                    <a href="https://www.linkedin.com/company/96215379/admin/feed/posts/" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
                        <FaLinkedin size={30} color="#0077b5" />
                    </a>
                    <a href="https://www.instagram.com/fetc_trainingcentre?igsh=NmI0bjM3NnY4ZXhh&utm_source=qr" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
                        <FaInstagram size={30} color="#e4405f" />
                    </a>
                    <a href="mailto:info@fetc.in" style={socialIconStyle}>
                        <FaEnvelope size={30} color="#ea4335" />
                    </a>
                </div>
            </div>
    
            {/* Footer Content Section */}
            <div style={footerContentStyle}>
                {/* Logo Section */}
                <div style={logoSectionStyle}>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                        <img
                            style={logoImageStyle}
                            src="/images/logo/FETC-logo.png"
                            alt="Footer Logo"
                        />
                    </Link>
                </div>
    
                {/* Explore Section */}
                <div style={exploreSectionStyle}>
                    <h3 style={exploreTitleStyle}>Explore</h3>
                    <ul style={exploreListStyle}>
                        <li style={exploreItemStyle}><Link to="/about-us" style={exploreLinkStyle}>About Us</Link></li>
                        <li style={exploreItemStyle}><Link to="/faq" style={exploreLinkStyle}>FAQs</Link></li>
                        <li style={exploreItemStyle}><Link to="/testimonial" style={exploreLinkStyle}>Testimonials</Link></li>
                        <li style={exploreItemStyle}><Link to="/book-an-appointment" style={exploreLinkStyle}>Book An Appointment</Link></li>
                        <li style={exploreItemStyle}><Link to="/contact-us" style={exploreLinkStyle}>Contact Us</Link></li>
                    </ul>
                </div>
    
                {/* Contact Section */}
                <div style={contactSectionStyle}>
                    <h3 style={contactTitleStyle}>Contact Us</h3>
                    <p style={addressItemStyle}>
                        <b>Head Office</b> <i className="icon-map-pin-line"></i> 238 – 239, Second Floor, Roongta Signature, Opp. Shyam Mandir, VIP Road, Vesu, Surat, India
                    </p>
                    <p style={addressItemStyle}>
                        <i className="icon-map-pin-line"></i> 328, 3rd Fl, Radhika Optima Near Yamuna Chowk, Mota Varachha Surat, India
                    </p>
                    <p style={addressItemStyle}>
                        <i className="icon-map-pin-line"></i> 210, Dhanrajni Complex, Near Imperial Palace Dr. Yagnik Road Rajkot, India
                    </p>
                    <p style={addressItemStyle}>
                        <i className="icon-ic-round-phone"></i> <a href="tel:+919033347201" style={phoneStyle}>+ 91 9033347201</a>
                    </p>
                </div>
            </div>
    
            {/* Copyright Section */}
            <div style={copyrightStyle}>
                <p style={copyrightTextStyle}>
                    Copyright 2024 <a href="/" style={copyrightLinkStyle}>FETC</a> All Rights Reserved
                </p>
            </div>
        </div>
    
        {/* Scroll to Top Button */}
        <ScrollTopButton />
    </footer>
    
    );
};

// Inline CSS styles
const footerStyle = {
    padding: '40px 50px', // Top and bottom 40px, left and right 50px for equal gaps
    background: 'linear-gradient(45deg, #feecde, #fff2e9, #fff8f2, #ebe5f1)', // Gradient color for footer background
    color: '#333', // Dark text color for readability
    textAlign: 'left',
    boxSizing: 'border-box', // Ensures padding is included in width calculation
};

// Inline CSS for wrapper to enforce the left-right gap
const footerWrapperStyle = {
    maxWidth: 'calc(100% - 100px)', // Subtract 50px gap on each side from 100%
    margin: '0 auto', // Center content horizontally
    boxSizing: 'border-box', // Prevent padding from affecting width
};

const socialSectionStyle = {
    textAlign: 'center',
    marginBottom: '20px',
};

const socialTitleStyle = {
  fontSize: '24px',           
  textAlign: 'right',        
  marginBottom: '10px',      
};


const socialIconsStyle = {
    display: 'flex',
    justifyContent: 'right',
    gap: '15px', // Space between icons
};

const socialIconStyle = {
    textDecoration: 'none',
};

const footerContentStyle = {
    display: 'flex',
    justifyContent: 'space-between', // Space between logo, explore, and contact sections
    alignItems: 'flex-start',
    marginBottom: '40px',
    flexWrap: 'wrap', // Ensures items wrap on smaller screens
};

const logoSectionStyle = {
    flex: '1 1 30%', // Allows the logo section to take up 30% of the space
    textAlign: 'center',
    marginBottom: '20px',
};

const logoImageStyle = {
    maxWidth: '150px',
};

const exploreSectionStyle = {
  flex: '1 1 30%', // Explore section takes up 30% of the space
  textAlign: 'left', // Change from 'center' to 'left'
  marginBottom: '20px',
};

const exploreTitleStyle = {
    fontSize: '24px',
    marginBottom: '10px',
};

const exploreListStyle = {
  listStyleType: 'none',
  padding: '0',
  margin: '0',
  display: 'flex',
  justifyContent: 'flex-start', // Change from 'center' to 'flex-start'
  flexDirection: 'column', // Stack items vertically
  alignItems: 'flex-start', // Align items to the left
};

const exploreItemStyle = {
  textDecoration: 'none',  // Optional, remove underline
  color: '#000',           // Optional, set link color
  display: 'block',        // Ensure each link takes up the full width of the list item
  textAlign: 'left',       // Align text to the left within the link
};

const exploreLinkStyle = {
    color: '#333',
    textDecoration: 'none',
    fontSize: '16px',
};

const contactSectionStyle = {
    flex: '1 1 30%', // Contact section takes up 30% of the space
    textAlign: 'center',
    marginBottom: '20px',
};

const contactTitleStyle = {
    fontSize: '24px',
    marginBottom: '10px',
};

const addressItemStyle = {
  margin: '10px 0',
  fontSize: '14px',
  textAlign: 'left', 
};


const phoneStyle = {
    color: '#333',
    textDecoration: 'none',
};

const copyrightStyle = {
    marginTop: '20px',
    marginBottom: '0px',
    textAlign: 'center',
};

const copyrightTextStyle = {
    fontSize: '14px',
};

const copyrightLinkStyle = {
    color: '#333',
    textDecoration: 'none',
};

export default Footer;
