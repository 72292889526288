// src/Unit1.js
import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const courses = [
  {
    title: 'Unit 1 A',
    description: 'Learn the basics of Unit 1 A.',
    link: '/courses/elementary/unit1/unit1a/1',
  },
  {
    title: 'Unit 1 B',
    description: 'Dive deeper into Unit 1 B concepts.',
    link: '/courses/elementary/unit1/unit1b/1',
  },
  {
    title: 'Unit 1 C',
    description: 'Advanced topics covered in Unit 1 C.',
    link: '/courses/elementary/unit1/unit1c/1',
  },
];

const Unit1 = () => {
  return (
    <>
      <SEO title="SyZyGy" />
      <Layout>
        <BreadcrumbOne 
          title="Unit 1 Courses"
          rootUrl="/"
          parentUrl="Courses"
          currentUrl="Unit 1"
        />
        <div style={styles.container}>
          {courses.map((course, index) => (
            <div key={index} style={styles.card} onClick={() => window.open(course.link, '_blank')}>
              <h2 style={styles.title}>{course.title}</h2>
              <p style={styles.description}>{course.description}</p>
            </div>
          ))}
        </div>
      </Layout>
    </>
  );
};

// Updated styles for better design
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center items horizontally
    padding: '20px',
    backgroundColor: '#f9f9f9',
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '20px',
    margin: '10px 0', // Vertical margin for spacing
    width: '100%', // Full width for responsiveness
    maxWidth: '600px', // Limit max width
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', // Enhanced shadow for depth
    transition: 'transform 0.3s, box-shadow 0.3s', // Smooth hover effect
    cursor: 'pointer', // Pointer cursor for clickable items
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '10px',
    color: '#333', // Darker color for better contrast
  },
  description: {
    fontSize: '16px',
    color: '#555',
    lineHeight: '1.5', // Better line height for readability
  },
};

// Adding hover effect for cards
const cardHover = {
  ...styles.card,
  ':hover': {
    transform: 'translateY(-5px)', // Slight upward movement on hover
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', // Deeper shadow on hover
  },
};

// To apply the hover effect, you can add a hover listener or use CSS-in-JS library like styled-components or emotion.

export default Unit1;
