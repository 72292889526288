import React, { useState } from 'react';

const Unit1CE16 = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',
    title: '',
    dob: '',
    gender: '',
    maritalStatus: '',
    nationality: '',
    placeOfBirthCountry: '',
    placeOfBirthCity: '',
    homeAddress: '',
    email: '',
    phoneNumber: '',
    homeLandline: '',
    mobile: '',
    passportNumber: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First name is required.';
    if (!formData.surname) newErrors.surname = 'Surname is required.';
    if (!formData.title) newErrors.title = 'Title is required (e.g., Mr, Ms).';
    if (!formData.dob) newErrors.dob = 'Date of birth is required.';
    if (!formData.gender) newErrors.gender = 'Please select your gender.';
    if (!formData.maritalStatus)
      newErrors.maritalStatus = 'Please select your marital status.';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = 'Please enter a valid email.';
    if (!formData.phoneNumber || !/^\d+$/.test(formData.phoneNumber))
      newErrors.phoneNumber = 'Phone number must be numeric.';
    if (!formData.passportNumber || !/(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/.test(formData.passportNumber)) {
      newErrors.passportNumber = 'Passport/ID number must include both letters and numbers.';
    }
    
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      alert('Form submitted successfully!');
      console.log(formData);
      setErrors({});
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: 'auto' }}>
      <h3 style={{ fontSize: '1.8rem', textAlign: 'center', marginBottom: '20px' }}>
      Complete the outline form with your information      </h3>
      <h4 style={{ fontSize: '1.4rem', marginBottom: '20px', textAlign: 'center' }}>
        VISIT AND SHORT STAY (VISAF2) <br />
        Visa application form for a visitor or student
      </h4>

      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <label>
          First name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Enter your first name"
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
          {errors.firstName && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.firstName}</span>}
        </label>

        <label>
          Surname (Family name):
          <input
            type="text"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
            placeholder="Enter your surname"
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
          {errors.surname && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.surname}</span>}
        </label>

        <label>
  Title:
  <select
    name="title"
    value={formData.title}
    onChange={handleChange}
    style={{ width: '100%', padding: '8px', marginTop: '5px' }}
  >
    <option value="">Select your title</option>
    <option value="Mr">Mr</option>
    <option value="Mrs">Mrs</option>
    <option value="Ms">Ms</option>
    <option value="Dr">Dr</option>
    <option value="Prof">Prof</option>
    <option value="Other">Other</option>
  </select>
  {errors.title && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.title}</span>}
</label>


        <label>
          Date of birth:
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
          {errors.dob && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.dob}</span>}
        </label>

        <label>
          Gender:
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          >
            <option value="">Select your gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {errors.gender && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.gender}</span>}
        </label>

        <label>
          Marital Status:
          <select
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          >
            <option value="">Select your marital status</option>
            <option value="single">Single</option>
            <option value="married">Married</option>
            <option value="divorced">Divorced</option>
            <option value="separated">Separated</option>
          </select>
          {errors.maritalStatus && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.maritalStatus}</span>}
        </label>

        <label>
          Email address:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter a valid email"
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
          {errors.email && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.email}</span>}
        </label>

        <label>
          Phone number:
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Numbers only"
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
          {errors.phoneNumber && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.phoneNumber}</span>}
        </label>

        <label>
          Passport/Identity card number:
          <input
            type="text"
            name="passportNumber"
            value={formData.passportNumber}
            onChange={handleChange}
            placeholder="Enter your passport/ID number"
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
          {errors.passportNumber && <span style={{ color: 'red', fontSize: '1.2rem' }}>{errors.passportNumber}</span>}
        </label>

        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#007BFF',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            fontSize: '1.1rem',
            borderRadius: '8px',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Unit1CE16;
