import React, { useState } from "react";

const questions = [
  { text: "1. Where are ____ from?", answer: "you" },
  { text: "2. ____'m from Gujarat.", answer: "I" },
  { text: "3. Where ____ Gujarat?", answer: "in" }, // No answer needed for this question
  { text: "4. ____'m from Surat.", answer: "I" },
];

const options = ["I", "you", "my","in", "Your"];

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    width: "300px",
  },
  closeButton: {
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
  },
};

const Unit1CE11 = () => {
  const [userAnswers, setUserAnswers] = useState(Array(questions.length).fill(""));
  const [feedback, setFeedback] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [score, setScore] = useState(0);

  const handleDrop = (index, option) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = option;
    setUserAnswers(updatedAnswers);
  };

  const handleSubmit = () => {
    let correctAnswers = 0;

    const feedbackMessages = questions.map((question, index) => {
      if (!question.answer) return ""; // Skip feedback for questions without answers
      if (userAnswers[index].toLowerCase() === question.answer.toLowerCase()) {
        correctAnswers++;
        return "✔️ Correct";
      } else {
        return `❌ Incorrect, answer is "${question.answer}"`;
      }
    });

    setFeedback(feedbackMessages);
    setScore(correctAnswers);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", textAlign: "center" }}>
      <h3 style={{ fontSize: "1.8rem", marginBottom: "20px" }}>
        Complete the questions with I, you, my, and your
      </h3>

      {/* Draggable options */}
      <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginBottom: "20px" }}>
        {options.map((option, index) => (
          <div
            key={index}
            draggable
            onDragStart={(e) => e.dataTransfer.setData("text/plain", option)}
            style={{
              padding: "10px 15px",
              backgroundColor: "#007BFF",
              color: "white",
              borderRadius: "5px",
              cursor: "grab",
              fontSize: "1.3rem",
            }}
          >
            {option}
          </div>
        ))}
      </div>

      {/* Questions with drop zones */}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
        {questions.map((question, index) => (
          <div key={index} style={{ fontSize: "1.6rem", display: "flex", alignItems: "center" }}>
            {question.text.split("____").map((part, i) => (
              <React.Fragment key={i}>
                {part}
                {i < question.text.split("____").length - 1 && question.answer !== null && (
                  <div
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(index, e.dataTransfer.getData("text/plain"))}
                    style={{
                      display: "inline-block",
                      width: "60px",
                      height: "30px",
                      margin: "0 5px",
                      border: "2px dashed #ccc",
                      borderRadius: "5px",
                      textAlign: "center",
                      lineHeight: "30px",
                      backgroundColor: userAnswers[index] ? "#DFF0D8" : "#FFF",
                    }}
                  >
                    {userAnswers[index]}
                  </div>
                )}
              </React.Fragment>
            ))}
            <div
              style={{
                color: feedback
                  ? feedback[index]?.includes("Correct")
                    ? "green"
                    : "red"
                  : "black",
                marginLeft: "10px",
              }}
            >
              {feedback && feedback[index]}
            </div>
          </div>
        ))}
      </div>

      {/* Submit button */}
      <button
        onClick={handleSubmit}
        style={{
          marginTop: "30px",
          padding: "10px 20px",
          borderRadius: "8px",
          backgroundColor: "#28A745",
          color: "white",
          border: "none",
          cursor: "pointer",
          fontSize: "1.3rem",
        }}
      >
        Submit Answers
      </button>

      {/* Modal for displaying results */}
      {showModal && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.modal}>
            <h3 style={{ color: "#007BFF" }}>Quiz Results</h3>
            <p>Correct: {score} / {questions.filter((q) => q.answer).length}</p>
            <p>Percentage: {Math.round((score / questions.filter((q) => q.answer).length) * 100)}%</p>
            <button onClick={handleCloseModal} style={modalStyles.closeButton}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Unit1CE11;
