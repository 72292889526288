import React, { useState } from 'react';

// Exercise data
const exercise = [
  { sentence: "1. her brother is from mumbai.", correctAnswer: "Her brother is from Mumbai." },
  { sentence: "2. i am going to surat on a holiday.", correctAnswer: "I am going to Surat on a holiday." },
  { sentence: "3. john lives in london and speaks english.", correctAnswer: "John lives in London and speaks English." },
  { sentence: "4. my mother is learning spanish.", correctAnswer: "My mother is learning Spanish." },
  { sentence: "5. the taj mahal is in agra, india.", correctAnswer: "The Taj Mahal is in Agra, India." },
  { sentence: "6. we will visit paris next friday.", correctAnswer: "We will visit Paris next Friday." },
  { sentence: "7. he said, i want to learn french.", correctAnswer: "He said, I want to learn French." },
];

// Modal styles
const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    maxWidth: "400px",
    width: "90%",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
  },
  closeButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

const Unit1CE15 = () => {
  const [answers, setAnswers] = useState([]); // Store user answers
  const [score, setScore] = useState(0); // Track score
  const [isSubmitted, setIsSubmitted] = useState(false); // Submission state
  const [showModal, setShowModal] = useState(false); // Modal visibility

  // Handle input changes
  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  // Handle Submit
  const handleSubmit = () => {
    let totalScore = 0;
    exercise.forEach((item, index) => {
      if (answers[index]?.trim() === item.correctAnswer) {
        totalScore++;
      }
    });
    setScore(totalScore);
    setIsSubmitted(true);
    setShowModal(true);
  };

  // Reset the form
  const handleReset = () => {
    setAnswers([]);
    setScore(0);
    setIsSubmitted(false);
    setShowModal(false);
  };

  return (
    <div style={styles.container}>
      {/* Title */}
      <h2 style={styles.title}>Correct the Sentences</h2>

      {/* Exercise Section */}
      <div>
        {exercise.map((item, index) => {
          let backgroundColor = ''; // Dynamic input background color
          if (isSubmitted) {
            backgroundColor =
              answers[index]?.trim() === item.correctAnswer
                ? '#d4edda' // Correct: Light green
                : '#f8d7da'; // Incorrect: Light red
          }

          return (
            <div key={index} style={styles.questionContainer}>
              <p style={styles.sentence}>{item.sentence}</p>
              <input
                type="text"
                placeholder="Type the corrected sentence"
                value={answers[index] || ''}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                style={{ ...styles.input, backgroundColor }}
              />
            </div>
          );
        })}
      </div>

      {/* Buttons */}
      <div style={styles.buttonContainer}>
        <button onClick={handleSubmit} style={styles.submitButton}>
          Submit Answers
        </button>
        <button onClick={handleReset} style={styles.resetButton}>
          Reset
        </button>
      </div>

      {/* Modal */}
      {showModal && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.modal}>
            <h3 style={styles.modalTitle}>Results</h3>
            <p style={styles.counterText}>Correct: {score} / {exercise.length}</p>
            <p style={styles.counterText}>Percentage: {Math.round((score / exercise.length) * 100)}%</p>
            <button onClick={() => setShowModal(false)} style={modalStyles.closeButton}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "600px",
    margin: "0 auto",
  },
  title: {
    textAlign: "center",
    color: "#333",
    fontSize: "3rem",
  },
  questionContainer: {
    marginBottom: "15px",
  },
  sentence: {
    margin: "5px 0",
    fontSize: "1.5rem",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    fontSize: "1.3rem",
  },
  buttonContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  submitButton: {
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1.2rem",
  },
  resetButton: {
    padding: "10px 20px",
    backgroundColor: "#28A745",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1.2rem",
  },
  modalTitle: {
    color: "#007BFF",
    marginBottom: "10px",
  },
  counterText: {
    fontSize: "1rem",
    margin: "5px 0",
  },
};

export default Unit1CE15;
