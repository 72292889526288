import React, { useState } from 'react';

// Array containing question texts and corresponding MP3 file paths
const questions = [
  { text: "What’s your first name?", audio: "/audio/practice-the-sentence-stress/whats-your-first-name.mp3" },
  { text: "What’s your surname?", audio: "/audio/practice-the-sentence-stress/whats-your-surname.mp3" },
  { text: "How do you spell it?", audio: "/audio/practice-the-sentence-stress/how-do-you-spell-it.mp3" },
  { text: "How old are you?", audio: "/audio/practice-the-sentence-stress/how-old-are-you.mp3" },
  { text: "Where are you from?", audio: "/audio/practice-the-sentence-stress/where-are-you-from.mp3" },
  { text: "What’s your address?", audio: "/audio/practice-the-sentence-stress/whats-your-address.mp3" },
  { text: "What’s your postcode?", audio: "/audio/practice-the-sentence-stress/whats-your-postcode.mp3" },
  { text: "What’s your email address?", audio: "/audio/practice-the-sentence-stress/whats-your-email-address.mp3" },
  { text: "What’s your phone number?", audio: "/audio/practice-the-sentence-stress/whats-your-phone-number.mp3" },
];

const Unit1CE10 = () => {
  const [audio, setAudio] = useState(null);
  const [recordedTexts, setRecordedTexts] = useState({});
  const [isRecording, setIsRecording] = useState({});
  const [recognitionInstances, setRecognitionInstances] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [score, setScore] = useState(0);

  // Play audio for each question
  const handlePlayAudio = (audioPath) => {
    if (audio) audio.pause();
    const newAudio = new Audio(audioPath);
    setAudio(newAudio);
    newAudio.play();
  };

  // Start or stop speech recognition
  const toggleRecording = (index) => {
    isRecording[index] ? stopRecording(index) : startRecording(index);
  };

  const startRecording = (index) => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = 'en-US';
    recognition.interimResults = true;

    setRecordedTexts((prev) => ({ ...prev, [index]: '' }));

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results).map((result) => result[0].transcript).join(' ');
      setRecordedTexts((prev) => ({ ...prev, [index]: transcript }));
    };

    recognition.onerror = () => {
      alert('Speech recognition error. Please try again.');
      stopRecording(index);
    };

    recognition.onend = () => {
      setIsRecording((prev) => ({ ...prev, [index]: false }));
    };

    if (recognitionInstances[index]) recognitionInstances[index].abort();

    setRecognitionInstances((prev) => ({ ...prev, [index]: recognition }));
    recognition.start();
    setIsRecording((prev) => ({ ...prev, [index]: true }));
  };

  const stopRecording = (index) => {
    if (recognitionInstances[index]) recognitionInstances[index].stop();
    setIsRecording((prev) => ({ ...prev, [index]: false }));
  };

  const handleSubmitB = () => {
    let currentScore = 0;
    questions.forEach((question, index) => {
      if (recordedTexts[index]?.toLowerCase() === question.text.toLowerCase()) currentScore++;
    });
    setScore(currentScore);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
    },
    closeButton: {
      marginTop: '10px',
      padding: '8px 16px',
      borderRadius: '5px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
    },
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
      <h3 style={{ fontSize: '1.8rem', marginBottom: '20px' }}>Focus on the Sentence Stress</h3>
      <p style={{ fontSize: '1.3rem', marginBottom: '20px' }}>
        Go through it with the class. Getting the rhythm right when you speak will help them to understand and be understood.
      </p>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
        {questions.map((question, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '600px',
              padding: '15px',
              borderRadius: '8px',
              backgroundColor: '#f0f0f0',
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            }}
          >
            <div style={{ flex: 5, textAlign: 'left', fontSize: '1.5rem' }}>{question.text}</div>
            <button
              onClick={() => handlePlayAudio(question.audio)}
              style={{
                margin: '0 10px',
                padding: '8px 12px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Play
            </button>
            <button
              onClick={() => toggleRecording(index)}
              style={{
                margin: '0 10px',
                padding: '8px 12px',
                backgroundColor: isRecording[index] ? 'red' : '#28a745',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              {isRecording[index] ? 'Stop' : 'Record'}
            </button>
            <input
              type="text"
              value={recordedTexts[index] || ''}
              placeholder="Speak here..."
              readOnly
              style={{
                flex: 3,
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontSize: '1.5rem',
              }}
            />
          </div>
        ))}

        <button
          onClick={handleSubmitB}
          style={{
            marginTop: '30px',
            padding: '10px 20px',
            borderRadius: '8px',
            backgroundColor: '#28A745',
            color: '#FFFFFF',
            border: 'none',
            cursor: 'pointer',
            fontSize: '1.5rem',
            width: '20%',
          }}
        >
          Submit Answers
        </button>
      </div>

      {/* Modal for displaying results */}
      {showModal && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.modal}>
            <h3 style={{ color: "#007BFF" }}>Results</h3>
            <p>Correct: {score} / {questions.length}</p>
            <p>Percentage: {Math.round((score / questions.length) * 100)}%</p>
            <button onClick={handleCloseModal} style={modalStyles.closeButton}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Unit1CE10;
