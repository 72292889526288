import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// CourseTypeData component for displaying individual course cards
const CourseTypeData = ({ data, classes, bgWhite }) => {
    return (
        <div className={`edu-card card-type-1 radius-small ${classes ? classes : ''} ${bgWhite === 'enable' ? 'bg-white' : ''}`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={`${process.env.PUBLIC_URL}/courses/elementary/unit1/`}>
                        <img className="w-100" src={`${process.env.PUBLIC_URL}/images/course/course-01/${data.image}`} alt="Course Thumb" />
                    </Link>
                </div>
                <div className="content">
                    <ul className="edu-meta meta-01">
                        <li><i className="icon-file-list-4-line"></i>{data.lesson} Lessons</li>
                        <li><i className="icon-time-line"></i>{data.duration}</li>
                    </ul>
                    <h6 className="title">
                        <Link to={`${process.env.PUBLIC_URL}/course-details/${data.id}`}>{data.title}</Link>
                    </h6>
                    <div className="edu-rating rating-default">
                        <div className="rating eduvibe-course-rating-stars">
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                        </div>
                        <span className="rating-count">({data.review} Review)</span>
                    </div>
                    <div className="card-bottom">
                        <div className="price-list price-style-03">
                            {data.price === 0 ? (
                                <div className="price current-price">Free</div>
                            ) : (
                                <div className="price current-price">₹{data.price}</div>
                            )}
                            {data.oldPrice && <div className="price old-price">${data.oldPrice}</div>}
                        </div>
                        <ul className="edu-meta meta-01">
                            <li><i className="icon-account-circle-line"></i>{data.student} Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Mock API fetch for course data
export const fetchMockCourses = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const mockResponse = {
                data: {
                    message: [
                        {
                            name: "Unit 1",
                            title: "Unit 1",
                            tags: [],
                            description: "Unit 1",
                            image: "Unit1C.png",
                            short_introduction: "Unit 1",
                            enrollment_count: 2,
                            avg_rating: 0.0,
                            lesson_count: 6,
                            instructors: [
                                {
                                    full_name: "Bhumika Dilkhush",
                                    user_image: "https://secure.gravatar.com/avatar/1312523c3348113cfb3f7fc4338a4b90?d=404&s=200",
                                },
                            ],
                            course_price: 0.0,
                            currency: "INR",
                        },
                    ],
                },
            };
            resolve(mockResponse);
        }, 1000);
    });
};

// Main component to render the course list
const ElementaryData = () => {
    const [courseItems, setCourseItems] = useState([]);
    const [loading, setLoading] = useState(false);

    // Function to format data from API response
    const formatCourseData = (apiResponse) => {
        const courses = apiResponse.message;
        return courses.map((course, index) => ({
            id: index + 1,
            title: course.title || 'No Title',
            image: course.image || 'default.jpg',
            instructor: course.instructors.length ? course.instructors[0].full_name : 'Unknown Instructor',
            duration: '3 - 4 Hours',
            level: 'Available Now',
            rating: course.avg_rating || 0,
            student: course.enrollment_count || 0,
            lesson: course.lesson_count || 0,
            price: course.course_price,
            oldPrice: '',
            review: course.avg_rating || 0,
            excerpt: course.short_introduction || 'No introduction available',
            details: `<h5>Course Description</h5> ${course.description || ''}`,
        }));
    };

    // Fetch course data on component mount
    const fetchCourses = async () => {
        try {
            setLoading(true);
            const response = await fetchMockCourses();
            const formattedData = formatCourseData(response.data);
            setCourseItems(formattedData);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setCourseItems([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    return (
        <>
            <div className="edu-course-area edu-section-gap bg-color-white">
                <div className="container">
                    <div className="row g-5 mt--10">
                        <h3>Courses</h3>
                        {loading ? (
                            <div className="d-flex align-items-center">
                                <strong>Loading...</strong>
                                <div className="spinner-border ms-auto" aria-hidden="true"></div>
                            </div>
                        ) : (
                            courseItems.map((item) => (
                                <div className="col-12 col-sm-6 col-lg-4" key={item.id}>
                                    <CourseTypeData data={item} />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ElementaryData;
