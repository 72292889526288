// src/pages/Unit1CE7.js

import React, { useState } from 'react';

// Define quiz data for each question
const quizData = [
  { id: 1, letters: ['E', 'A', 'I'], answer: 'E' },
  { id: 2, letters: ['G', 'J'], answer: 'J' },
  { id: 3, letters: ['K', 'Q'], answer: 'Q' },
  { id: 4, letters: ['C', 'S'], answer: 'C' },
  { id: 5, letters: ['V', 'P', 'B'], answer: 'B' },
  { id: 6, letters: ['M', 'N'], answer: 'M' },
  { id: 7, letters: ['V', 'W'], answer: 'V' },
  { id: 8, letters: ['Y', 'U'], answer: 'Y' },
];

const Unit1CE8 = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [feedback, setFeedback] = useState({});

  const handleListen = (letter) => {
    const utterance = new SpeechSynthesisUtterance(letter);
    utterance.volume = 10; // Maximum volume
    utterance.rate = 0.8; // Slightly slower for clarity
    utterance.pitch = 0.9; // Normal pitch
    window.speechSynthesis.speak(utterance);
  };
  

  const handleSelect = (questionId, selectedLetter) => {
    setSelectedAnswers({ ...selectedAnswers, [questionId]: selectedLetter });

    // Check if answer is correct and provide feedback
    const correctAnswer = quizData.find((q) => q.id === questionId).answer;
    setFeedback({
      ...feedback,
      [questionId]: selectedLetter === correctAnswer ? 'Correct!' : 'Try again!',
    });
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h4>Listen to the group of letters and select the one you hear.</h4>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {quizData.map((question) => (
          <div
            key={question.id}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
              width: '12%', // Make each question container width smaller for side-by-side layout
              border: '1px solid #ddd',
              borderRadius: '8px',
              marginBottom: '20px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <button
              onClick={() => handleListen(question.answer)}
              style={{
                padding: '5px 10px',
                marginBottom: '10px',
                backgroundColor: '#007BFF',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Listen
            </button>
            <div>
              {question.letters.map((letter) => (
                <button
                  key={letter}
                  onClick={() => handleSelect(question.id, letter)}
                  style={{
                    padding: '5px',
                    margin: '5px',
                    backgroundColor: selectedAnswers[question.id] === letter ? '#ffc107' : '#f8f9fa',
                    border: '1px solid #ced4da',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  {letter}
                </button>
              ))}
            </div>
            {feedback[question.id] && (
              <div
                style={{
                  marginTop: '5px',
                  fontWeight: 'bold',
                  color: feedback[question.id] === 'Correct!' ? 'green' : 'red',
                }}
              >
                {feedback[question.id]}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Unit1CE8;
