import React from 'react';

// Capitalization rules for the quiz
const rules = [
  "Names and surnames should always start with a capital letter.",
  "Continents, countries, nationalities, and languages are capitalized.",
  "Towns and cities also begin with capital letters.",
  "Days of the week are capitalized.",
  "The first word in a sentence should begin with a capital letter.",
  "The pronoun 'I' and 'She' (when used as the subject) are capitalized.",
];

const Unit1CE14 = () => {
  return (
    <div style={styles.container}>
      {/* Section Title */}
      <h5 style={styles.title}>
        Follow the capitalization rules and correct the sentences.
      </h5>

      {/* Display Rules */}
      <div>
        <ul style={styles.rulesList}>
          {rules.map((rule, index) => (
            <li key={index} style={styles.ruleItem}>
              {rule}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: "30px",
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "0 auto",
    lineHeight: "1.8",
  },
  title: {
    color: "#333",
    fontSize: "3rem", // Increased size
    marginBottom: "20px",
    textAlign: "center",
    fontWeight: "bold",
  },
  rulesList: {
    listStyleType: "disc",
    paddingLeft: "30px",
    color: "#555",
  },
  ruleItem: {
    marginBottom: "12px",
    fontSize: "1.5rem", // Increased size
    lineHeight: "1.8",
  },
};

export default Unit1CE14;
