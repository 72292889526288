import React, { useState } from "react";

const Unit1CE17 = () => {
  const [text, setText] = useState("");
  const [feedback, setFeedback] = useState([]);

  const wordLimit = 100;
  const wordCount = text.trim().split(/\s+/).filter(Boolean).length;

  const handleChange = (e) => {
    const words = e.target.value.trim().split(/\s+/).filter(Boolean);
    if (words.length <= wordLimit) {
      setText(e.target.value);
    } else {
      alert("You cannot write more than 100 words.");
    }
  };

  const handleSubmit = () => {
    const sentences = text.split(/[.!?]\s*/); // Split text into sentences
    const grammarErrors = checkGrammar(sentences);
    setFeedback(grammarErrors);
  };

  const checkGrammar = (sentences) => {
    const grammarIssues = [];
    const verbIssues = /(?:\bis\b|\bam\b|\bare\b|\bwas\b|\bwere\b|\bhave\b|\bhas\b|\bhad\b|\bwill\b|\bwould\b)/gi;
    const articleIssues = /\b(a\s[aeiou]|an\s[^aeiou])/gi; // Check for wrong article use

    sentences.forEach((sentence, index) => {
      let issues = [];

      // Verb form validation
      if (!verbIssues.test(sentence)) {
        issues.push("Missing or incorrect verb usage");
      }

      // Article validation
      if (articleIssues.test(sentence)) {
        issues.push("Incorrect article usage");
      }

      // Check for tense consistency (simplified approach)
      const pastWords = /\b(was|were|had)\b/gi;
      const presentWords = /\b(is|am|are|have|has)\b/gi;
      const futureWords = /\b(will|shall)\b/gi;

      const pastCount = (sentence.match(pastWords) || []).length;
      const presentCount = (sentence.match(presentWords) || []).length;
      const futureCount = (sentence.match(futureWords) || []).length;

      if ([pastCount, presentCount, futureCount].filter((count) => count > 0).length > 1) {
        issues.push("Inconsistent tense usage");
      }

      // Add issues to feedback if any
      if (issues.length > 0) {
        grammarIssues.push({
          index,
          text: sentence.trim(),
          issues,
        });
      }
    });

    return grammarIssues;
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", textAlign: "center" }}>
      <h3 style={{ fontSize: "1.8rem", marginBottom: "20px" }}>Write about yourself</h3>
      <p style={{ fontSize: "1.8rem", marginBottom: "10px", color: "gray" }}>
        Please write a short paragraph about yourself (not more than 100 words).
      </p>
      <div style={{ position: "relative", width: "100%" }}>
        <textarea
          value={text}
          onChange={handleChange}
          rows="10"
          style={{
            width: "100%",
            padding: "10px",
            fontSize: "1.5rem",
            border: "2px solid #ccc",
            borderRadius: "5px",
            outline: "none",
          }}
        />
        <span
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            fontSize: "1.2rem",
            color:
              wordCount < wordLimit ? "red" : wordCount === wordLimit ? "green" : "red",
          }}
        >
          {wordCount}/{wordLimit} words
        </span>
      </div>
      <br />

      {/* Submit button */}
      <button
        onClick={handleSubmit}
        style={{
          marginTop: "30px",
          padding: "10px 20px",
          borderRadius: "8px",
          backgroundColor: "#28A745",
          color: "white",
          border: "none",
          cursor: "pointer",
          fontSize: "1.2rem",
        }}
      >
        Submit Answers
      </button>

      {/* Inline Feedback */}
      {feedback.length > 0 && (
        <div style={{ marginTop: "20px", textAlign: "left" }}>
          <h4 style={{ color: "#007BFF" }}>Feedback</h4>
          {feedback.length === 0 ? (
            <p style={{ color: "green" }}>Your writing is grammatically correct!</p>
          ) : (
            <div>
              <p style={{ color: "red" }}>Some sentences have grammar issues:</p>
              <ul>
                {feedback.map((error) => (
                  <li
                    key={error.index}
                    style={{
                      backgroundColor: "#F8D7DA",
                      padding: "5px",
                      borderRadius: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <strong>Sentence #{error.index + 1}:</strong> {error.text}
                    <ul>
                      {error.issues.map((issue, i) => (
                        <li key={i} style={{ color: "darkred" }}>
                          {issue}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Unit1CE17;
