import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutSix = () => {
    const [toggler, setToggler] = useState(false);
    return (
        <>
            <div className="edu-workshop-area eduvibe-home-three-video workshop-style-1 edu-section-gap bg-image bg-color-primary">
                <div className="container eduvibe-animated-shape">
                    <div className="row gy-lg-0 gy-5 row--60 align-items-center justify-content-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="workshop-inner text-center">
                                <SectionTitle
                                    classes="text-white"
                                    slogan="Success Stories"
                                    title="VISION" 
                                />
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <div>
                                        <ul className="list-unstyled">
                                            {["Visa Assistance", "International Exam Prep", "Study Support", "Individual Coaching", "Organised Resources", "Networked Services"].map((item, index) => (
                                                <li key={index} className='white-text' style={{ fontSize: '40px', textAlign: 'left' }}>
                                                    <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{item.charAt(0)}</span>{item.slice(1)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="read-more-btn"
                                    animateOnce={true}
                                >
                                    {/* <Link className="edu-btn btn-white" to="/event-list">More Upcoming Workshop<i className="icon-arrow-right-line-right"></i></Link> */}
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-09-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-04-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-13-02.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutSix;
