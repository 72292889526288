import React, { useState } from 'react';

// Exercise data for Exercise A
const exerciseA = [
  { text: "The students are from Italy. ___________ names are Susanna and Tito.", answer: "Their" },
  { text: "She's in my class. ___________ name is Rebecca.", answer: "Her" },
  { text: "We're in class 2. ___________ teacher is Richard.", answer: "Our" },
  { text: "London is famous for ___________ red buses.", answer: "its" },
  { text: "How do you spell ___________ surname?", answer: "your" },
  { text: "This is my teacher. ___________ name is John.", answer: "His" },
  { text: "I'm from London. Old Kent Road. ___________ address is 31.", answer: "My" },
  { text: "Sit down and open ___________ books, please.", answer: "your" },
  { text: "Laura is in my class. ___________ desk is near the window.", answer: "Her" },
  { text: "We're from Liverpool. ___________ surname is Connor.", answer: "Our" },
];

const ExerciseA = () => {
  const [selectedOptionsA, setSelectedOptionsA] = useState(Array(exerciseA.length).fill(''));
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);  // Modal visibility state
  const [score, setScore] = useState(0);  // Score state

  // Handle changes for Exercise A
  const handleChangeA = (index, value) => {
    const updatedAnswers = [...selectedOptionsA];
    updatedAnswers[index] = value;
    setSelectedOptionsA(updatedAnswers);
  };

  // Handle submission for Exercise A
  const handleSubmitA = () => {
    let correctCount = 0;

    // Check answers for Exercise A
    exerciseA.forEach((item, index) => {
      if (selectedOptionsA[index]?.toLowerCase() === item.answer.toLowerCase()) {
        correctCount++;
      }
    });

    setScore(correctCount);
    setSubmitted(true);
    setShowModal(true);  // Show the modal after submission
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Modal styles
  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      width: '300px',
    },
    closeButton: {
      marginTop: '15px',
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  // The options to choose from
  const options = ["their", "her", "his", "our", "its", "your", "my"];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ width: '50%', border: '1px solid #ddd', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
        <h4 style={{ fontSize: '1.4rem', marginBottom: '15px', textAlign: 'center' }}>a. Complete the sentences with a possessive adjective</h4>
        
        {/* Show the options above the form */}
        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <span style={{ fontSize: '1.3rem' }}>Choose from: </span>
          {options.map((option, index) => (
            <span key={index} style={{
              padding: '5px 15px',
              margin: '0 5px',
              backgroundColor: '#f0f0f0',
              borderRadius: '5px',
              fontSize: '1.3rem',
              cursor: 'pointer'
            }}>
              {option}
            </span>
          ))}
        </div>

        {/* The exercise questions */}
        {exerciseA.map((item, index) => (
          <div key={index} style={{ marginBottom: '15px', textAlign: 'left' }}>
            <div>{item.text.split("___________").map((part, i) => (
              <React.Fragment key={i}>
                {part}
                {i < item.text.split("___________").length - 1 && (
                  <input
                    type="text"
                    value={selectedOptionsA[index]}
                    onChange={(e) => handleChangeA(index, e.target.value)}
                    style={{
                      width: '60px',
                      padding: '5px',
                      marginLeft: '5px',
                      textAlign: 'center',
                      fontSize: '1.7rem',
                      backgroundColor:
                        submitted
                          ? selectedOptionsA[index]?.toLowerCase() === item.answer.toLowerCase()
                            ? 'lightgreen'
                            : selectedOptionsA[index] !== ''
                            ? 'lightcoral'
                            : 'white'
                          : 'white',  // Default color before submission
                    }}
                  />
                )}
              </React.Fragment>
            ))}</div>
          </div>
        ))}
        <button
          onClick={handleSubmitA}
          style={{
            marginTop: '30px',
            padding: '10px 20px',
            borderRadius: '8px',
            backgroundColor: '#28A745',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            fontSize: '1.3rem',
            display: 'block',
            width: '100%',
          }}
        >
          Submit Answers
        </button>
      </div>

      {/* Modal for displaying results */}
      {showModal && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.modal}>
            <h3 style={{ color: "#007BFF" }}>Results</h3>
            <p>Correct: {score} / {exerciseA.length}</p>
            <p>Percentage: {Math.round((score / exerciseA.length) * 100)}%</p>
            <button onClick={handleCloseModal} style={modalStyles.closeButton}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExerciseA;
