import React, { useState } from "react";

const Unit1CE6 = () => {
  const [recordedTexts, setRecordedTexts] = useState({});
  const [isRecording, setIsRecording] = useState({});
  const [recognitionInstances, setRecognitionInstances] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [scores, setScores] = useState(0);

  const letterGroups = [
    { letters: "ABC.", audio: "/audio/listen-and-repeat-the-alphabet/abc.mp3" },
    { letters: "DEF.", audio: "/audio/listen-and-repeat-the-alphabet/def.mp3" },
    { letters: "GHI.", audio: "/audio/listen-and-repeat-the-alphabet/ghi.mp3" },
    { letters: "JKL.", audio: "/audio/listen-and-repeat-the-alphabet/jkl.mp3" },
    { letters: "MNO.", audio: "/audio/listen-and-repeat-the-alphabet/mno.mp3" },
    { letters: "PQR.", audio: "/audio/listen-and-repeat-the-alphabet/pqr.mp3" },
    { letters: "STU.", audio: "/audio/listen-and-repeat-the-alphabet/stu.mp3" },
    { letters: "VWX.", audio: "/audio/listen-and-repeat-the-alphabet/vwx.mp3" },
    { letters: "YZ.", audio: "/audio/listen-and-repeat-the-alphabet/yz.mp3" },
  ];

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  const playAudio = (audioUrl) => {
    const audio = new Audio(audioUrl);
    audio.play();
  };

  const toggleRecording = (index) => {
    if (isRecording[index]) {
      stopRecording(index);
    } else {
      startRecording(index);
    }
  };

  const startRecording = (index) => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = "en-US";
    recognition.interimResults = true;

    // Clear previous text for the group
    setRecordedTexts((prev) => ({ ...prev, [index]: "" }));

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join(" ");
      setRecordedTexts((prev) => ({ ...prev, [index]: transcript }));
    };

    recognition.onerror = () => {
      alert("Error with speech recognition. Please try again.");
      stopRecording(index);
    };

    recognition.onend = () => {
      setIsRecording((prev) => ({ ...prev, [index]: false }));
    };

    if (recognitionInstances[index]) {
      recognitionInstances[index].abort(); // Safely abort previous instance
    }

    setRecognitionInstances((prev) => ({ ...prev, [index]: recognition }));
    recognition.start();
    setIsRecording((prev) => ({ ...prev, [index]: true }));
  };

  const stopRecording = (index) => {
    if (recognitionInstances[index]) {
      recognitionInstances[index].stop();
    }
    setIsRecording((prev) => ({ ...prev, [index]: false }));
  };

  const handleSubmit = () => {
    let score = 0;
    letterGroups.forEach((group, index) => {
      const recordedAnswer = (recordedTexts[index] || "").trim().toUpperCase();
      if (recordedAnswer === group.letters) {
        score++;
      }
    });
    setScores(score);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h4 style={{ textAlign: "center", marginBottom: "30px" }}>
        Practice Letter Groups
      </h4>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {letterGroups.map((group, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "15px",
              width: "300px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4> {group.letters}</h4>
            <button
              onClick={() => playAudio(group.audio)}
              style={{
                padding: "10px 20px",
                fontSize: "14px",
                cursor: "pointer",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "4px",
                marginBottom: "10px",
              }}
            >
              Listen
            </button>
            <div>
              <button
                onClick={() => toggleRecording(index)}
                style={{
                  padding: "10px",
                  backgroundColor: isRecording[index] ? "red" : "#28a745",
                  color: "white",
                  fontSize: "14px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {isRecording[index] ? "Stop Recording" : "Start Recording"}
              </button>
            </div>
            <div
              style={{
                marginTop: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                color: "#555",
                fontSize: "14px",
              }}
            >
              {recordedTexts[index] || "Text will appear here"}
            </div>
          </div>
        ))}
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handleSubmit}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Submit Answers
        </button>
      </div>

      {/* Modal for displaying results */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "300px",
              textAlign: "center",
            }}
          >
            <h3 style={{ color: "#007BFF" }}>Results</h3>
            <p>Your Score: {scores} / {letterGroups.length}</p>
            <button
              onClick={handleCloseModal}
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#28a745",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};



export default Unit1CE6;
