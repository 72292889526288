import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const CareerAssessment = () => {
  // States to hold login ID and password entered by the user
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // If the login ID or password are empty, we alert the user.
    if (!loginId || !password) {
      alert("Please enter both Login ID and Password.");
      return;
    }

    // Generate the link using the entered credentials
    const testUrl = `http://www.compasnow.com/validateTestLoad.action?id=${loginId}&password=${password}`;

    // Redirect the user to the generated link to take the test
    window.location.href = testUrl;
  };

  return (
    <>
      {/* SEO Component */}
      <SEO title="Career Assessment" />

      {/* Layout and Breadcrumb */}
      <Layout>
        <BreadcrumbOne 
          title="Competency Mapping and Career Direction"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Career Assessment"
        />

        {/* Main Content */}
        <div style={styles.pageWrapper}>
          <div style={styles.formContainer}>
            <h2 style={styles.header}>Competency Mapping and Career Direction Tool</h2>
            
           
            <p style={styles.description}>
              Due to lack of awareness of personal strengths & weaknesses and incomplete information about the core 
              competencies required for different job profiles, thousands of students land in wrong jobs where they 
              cannot perform well. Students whose competencies are assessed stand a good chance to get the right 
              job and sustain in the job.
            </p>
            
            
            <p style={styles.description}>
              In this regard, we present you the best Competency Mapping and Career Direction Tool that helps you avoid 
              ending up in the wrong jobs, guiding you to make appropriate choices depending on your capabilities and 
              skills as needed by the corporate world.
            </p>

           
            <p style={styles.description}>
              We are sure that this innovative and unique step will help you assess the areas where you need improvement, 
              find core competencies needed for different jobs, match your strengths to the right job, and achieve excellence.
            </p>

            <form onSubmit={handleSubmit} style={styles.form}>
              <div style={styles.inputContainer}>
                <label style={styles.label}>Login ID:</label>
                <input
                  type="text"
                  value={loginId}
                  onChange={(e) => setLoginId(e.target.value)}
                  placeholder="Enter your Login ID"
                  style={styles.input}
                />
              </div>
              <div style={styles.inputContainer}>
                <label style={styles.label}>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your Password"
                  style={styles.input}
                />
              </div>
              <div style={styles.buttonContainer}>
                <button type="submit" style={styles.submitButton}>Submit</button>
                <button
                  type="button"
                  onClick={() => { setLoginId(''); setPassword(''); }}
                  style={styles.cancelButton}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
};

// Styles for the component
const styles = {
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh',
    backgroundColor: '#f0f4f8',
    padding: '20px 0',
  },
  formContainer: {
    width: '600px',
    padding: '30px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    fontSize: '24px',
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'justify',
    marginBottom: '20px',
    color: '#666',
    fontSize: '14px',
    lineHeight: '1.6',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '8px',
    color: '#555',
    fontWeight: 'bold',
  },
  input: {
    padding: '12px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    outline: 'none',
    transition: 'border-color 0.3s ease',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  submitButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '48%',
    transition: 'background-color 0.3s ease',
  },
  cancelButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#6c757d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '48%',
    transition: 'background-color 0.3s ease',
  },
};

export default CareerAssessment;
