import React, { useState } from 'react';

const Unit1CE7 = () => {
  const alphabetExercises = [
    {
      image: "/images/exercise/alphabet-chart-1.png",
      phoneme: "/eɪ/",
      letters: ['A', 'H', 'J', '___'], // One blank space A, H, J, (answer is K)
      correctAnswer: 'K',
    },
    {
      image: "/images/exercise/alphabet-chart-2.png",
      phoneme: "/iː/",
      letters: ['B', '___', '___', 'E', 'G', 'P', 'T', '___'], // Three blanks (answer is C, D) and V
      correctAnswers: ['C', 'D', 'V'],
    },
    {
      image: "/images/exercise/alphabet-chart-3.png",
      phoneme: "/e/",
      letters: ['F', 'L', 'M', '___', '___', 'X', 'Z'], // Two blanks (answer is N, S)
      correctAnswers: ['N', 'S'],
    },
    {
      image: "/images/exercise/alphabet-chart-4.png",
      phoneme: "/aɪ/",
      letters: ['___', 'Y'], // One blank (answer is I)
      correctAnswer: 'I',
    },
    {
      image: "/images/exercise/alphabet-chart-5.png",
      phoneme: "/əʊ/",
      letters: ['___'], // One blank (answer is O)
      correctAnswer: 'O',
    },
    {
      image: "/images/exercise/alphabet-chart-6.png",
      phoneme: "/uː/",
      letters: ['Q', '___', 'W'], // One blank (answer is U)
      correctAnswer: 'U',
    },
    {
      image: "/images/exercise/alphabet-chart-7.png",
      phoneme: "/ɑː/",
      letters: ['___'], // One blank (answer is R)
      correctAnswer: 'R',
    },
  ];

  // Flatten all correct answers for the options
  const correctAnswers = alphabetExercises
    .map(exercise => Array.isArray(exercise.correctAnswers) ? exercise.correctAnswers : [exercise.correctAnswer])
    .flat();

  const [userAnswers, setUserAnswers] = useState(
    alphabetExercises.map(exercise => (Array.isArray(exercise.correctAnswers) ? [] : ''))
  );
  const [draggedOption, setDraggedOption] = useState(null);
  const [availableOptions, setAvailableOptions] = useState(correctAnswers);
  const [optionToReset, setOptionToReset] = useState(null); // Store option to reset to available options
  const [showModal, setShowModal] = useState(false);
  const [score, setScore] = useState(0);

  // Handle the start of the drag event
  const handleDragStart = (option) => {
    setDraggedOption(option);
  };

  // Handle the drag over event
  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior to allow dropping
  };

  // Handle the drop event
  const handleDrop = (exerciseIndex, blankIndex) => {
    if (!draggedOption) return;

    const updatedAnswers = [...userAnswers];
    const updatedOptions = [...availableOptions];

    // Check if the blank is already filled
    const currentAnswer = updatedAnswers[exerciseIndex][blankIndex];
    if (currentAnswer === draggedOption) return; // Ignore if same option is dropped again

    if (currentAnswer) {
      // If there's already an answer in the blank, return the old answer back to the options
      updatedOptions.push(currentAnswer);
    }

    // Update the answers
    const newAnswers = [...updatedAnswers[exerciseIndex]];
    newAnswers[blankIndex] = draggedOption;
    updatedAnswers[exerciseIndex] = newAnswers;

    // Remove the dragged option from available options
    const optionIndex = updatedOptions.indexOf(draggedOption);
    if (optionIndex > -1) {
      updatedOptions.splice(optionIndex, 1);
    }

    setUserAnswers(updatedAnswers);
    setAvailableOptions(updatedOptions);
    setDraggedOption(null); // Reset dragged option after drop
  };

  // Reset the answers and options to their initial states
  const resetExercise = () => {
    setUserAnswers(alphabetExercises.map(exercise => (Array.isArray(exercise.correctAnswers) ? [] : '')));
    setAvailableOptions(correctAnswers);
    setScore(0);
    setOptionToReset(null); // Reset the reset option
  };

  // Calculate the score and show modal
  const handleSubmit = () => {
    let correctCount = 0;

    userAnswers.forEach((answer, index) => {
      const correctAnswer = alphabetExercises[index].correctAnswers || [alphabetExercises[index].correctAnswer];
      if (
        Array.isArray(answer)
          ? answer.every(ans => correctAnswer.includes(ans))
          : correctAnswer.includes(answer)
      ) {
        correctCount++;
      }
    });

    setScore(correctCount);
    setShowModal(true);
  };

  // Close the modal and reset the exercise
  const handleCloseModal = () => {
    setShowModal(false);
    resetExercise(); // Reset answers and options
  };

  // Modal Styles
  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      width: '300px',
    },
    closeButton: {
      marginTop: '20px',
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h4>Exercise: Alphabet Chart</h4>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', overflowX: 'auto' }}>
        {alphabetExercises.map((exercise, exerciseIndex) => (
          <div key={exerciseIndex} style={{ margin: '10px', width: 'calc(100% / 7 - 20px)' }}>
            <img
              src={exercise.image}
              alt={`Alphabet chart ${exerciseIndex + 1}`}
              style={{ width: '100%', borderRadius: '8px' }}
            />
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <div>
                <strong>Phoneme: </strong>
                <span>{exercise.phoneme}</span>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
              {/* Display letters and blank spaces */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {exercise.letters.map((letter, blankIndex) => (
                  <div
                    key={blankIndex}
                    onDrop={(e) => handleDrop(exerciseIndex, blankIndex)}
                    onDragOver={handleDragOver}
                    style={{
                      display: 'inline-block',
                      padding: '10px',
                      margin: '5px',
                      marginBottom:'5px',
                      backgroundColor: letter === '___' ? '#ADD8E6' : '#007BFF', // Black background for the blank spaces
                      color: letter === '___' ? '#007BFF' : '#fff', // White text color for readability
                      borderRadius: '5px',
                      width: '40px',
                      height: '40px',
                      textAlign: 'center',
                      lineHeight: '40px',
                      fontSize: '16px',
                      cursor: 'pointer',
                    }}
                  >
                    {userAnswers[exerciseIndex][blankIndex] || (letter === '___' ? '' : letter)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Options Section */}
      <div style={{ marginTop: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          {availableOptions.map((option, index) => (
            <div
              key={index}
              draggable={!userAnswers.some(answer => answer === option)} // Prevent dragging already used options
              onDragStart={() => handleDragStart(option)}
              style={{
                padding: '10px',
                backgroundColor: '#007BFF',
                color: '#fff',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
              }}
            >
              {option}
            </div>
          ))}
        </div>
      </div>

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        disabled={userAnswers.some((answer) => answer === null || (Array.isArray(answer) && answer.includes(''))) }
        style={{
          width: "100%",
          padding: "8px 12px",
          backgroundColor: "#007BFF",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          marginTop: "10px",
          fontSize: "14px",
        }}
      >
        Submit Answers
      </button>

      {/* Modal for displaying results */}
      {showModal && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.modal}>
            <h3 style={{ color: "#007BFF" }}>Results</h3>
            <p>Correct: {score} / {alphabetExercises.length}</p>
            <p>Percentage: {Math.round((score / alphabetExercises.length) * 100)}%</p>
            <button onClick={handleCloseModal} style={modalStyles.closeButton}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Unit1CE7;
