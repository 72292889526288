import React from 'react'
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout'
import CustomCounter from '../../components/counterup/CustomCounter'
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import { Navigate } from 'react-router-dom';

export const AboutCountryUsa = () => {

        const usaBenefits = [
            {
                title: 'World-Class Education',
                icon: 'icon-Bag',
                number: 550,
                suffix: '+'
            },
            {
                title: 'Diverse Academic Programs',
                icon: 'icon-trophy',
                number: 100,
                suffix: '%'
            },
            {
                title: 'Top-Ranked Universities',
                icon: 'icon-Open-book',
                number: 300,
                suffix: '+'
            },
            {
                title: 'Strong Industry Connections',
                icon: 'icon-Bag',
                number: 550,
                suffix: '+'
            }
        ];

        const handleClick = () => {
            Navigate('/contact-us');
        };

    const usaItems = [
        {
            title: 'Full Sail University',
            numberOfCourses: '',
            icon: 'icon-Schoolbag',
            link: '#'
        },
        {
            title: 'Tiffin University',
            numberOfCourses: '',
            icon: 'icon-student-read',
            link: '#'
        },
        {
            title: 'Schiller International University',
            numberOfCourses: '',
            icon: 'icon-Pencil',
            link: '#'
        },
        {
            title: 'George Stevens Academy',
            numberOfCourses: '',
            icon: 'icon-Class',
            link: '#'
        },
        {
            title: 'Hawai Pacific University',
            numberOfCourses: '',
            icon: 'icon-Setting',
            link: '#'
        },
        {
            title: 'California State University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Southern Hampshire University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'San Francisco University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'San Jose State University',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'New York Institute of Technology',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'University of Massachusetts Boston',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
        {
            title: 'Webster University ',
            numberOfCourses: "",
            icon: 'icon-Mind',
            link: '#'
        },
    ];

    const usaData = {
        title: 'USA',
        mTitle: 'Why Study in the USA?',
        desc_1: 'The United States stands as a global leader in higher education, offering an unparalleled diversity of academic programs and institutions. From Ivy League universities to cutting-edge research facilities, the USA provides students with opportunities to engage with world-class faculty, access state-of-the-art resources, and participate in innovative research that shapes the future. The cultural richness and inclusivity of American campuses foster a dynamic learning environment where students from all over the world come together, enriching their educational experience beyond the classroom.',
        desc_2: 'In the USA, you’ll find not only a focus on academic excellence but also a strong support system for international students, ensuring that your transition to life abroad is smooth and rewarding. Whether you aim to advance in technology, business, the arts, or any other field, studying in the USA equips you with the knowledge, skills, and global perspective needed to excel in today’s competitive world.',
        subTitle: 'Features of USA',
        funFacts: usaBenefits,
        items: usaItems
    }
    return (
        <>
            <SEO title={`About ${usaData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Study Abroad"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Study Abroad"
                />
                <CustomHomeAbout
                    sectionFormat={'1'} //2nd isn't looking goos fix it
                    title={usaData.title}
                    mTitle={usaData.mTitle}
                    desc_1={usaData.desc_1}
                    desc_2={usaData.desc_2}
                    subTitle={usaData.subTitle}
                    imgSrc={'/images/about/about-07/usa1.png'}

                />
                <CustomCounter
                    funFacts={usaData.funFacts}
                />

                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities"
                            />
                        </div>
                    </div>

                    <CategoryThreeCustom
                        items={usaData.items}
                    />


                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={() => handleClick()}>
                        <span>Get in touch with us</span>
                    </button>
                </div>

            </Layout>

        </>
    )
}
