import React, { useState } from 'react';

const ContactFormAirTickets = () => {
    const [formData, setFormData] = useState({
        fromLocation: '',
        destination: '',
        departDate: '',
        returnDate: '',
        travellers: { adults: 1, children: 0 },
        cabinClass: 'Economy',
        email: '',
        phone: ''
    });

    const [isTravellersDropdownOpen, setTravellersDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleTravellersChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            travellers: {
                ...formData.travellers,
                [name]: value
            }
        });
    };

    const handleTravellersToggle = () => {
        setTravellersDropdownOpen(!isTravellersDropdownOpen);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const travellerSummary = `${formData.travellers.adults} Adult${formData.travellers.adults > 1 ? 's' : ''}, ${formData.travellers.children} Child${formData.travellers.children > 1 ? 'ren' : ''}`;

    return (
        <div>
            <form onSubmit={handleSubmit} className="contact-form">
                <h4>Book Your Air Tickets</h4>

                <div className="form-group">
                    <label>From</label>
                    <input
                        type="text"
                        name="fromLocation"
                        value={formData.fromLocation}
                        onChange={handleChange}
                        required
                        placeholder="Enter departure location"
                    />
                </div>

                <div className="form-group">
                    <label>Destination</label>
                    <input
                        type="text"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}
                        required
                        placeholder="Enter destination"
                    />
                </div>

                <div className="form-group">
                    <label>Depart Date</label>
                    <input
                        type="date"
                        name="departDate"
                        value={formData.departDate}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Return Date</label>
                    <input
                        type="date"
                        name="returnDate"
                        value={formData.returnDate}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <p>Select Cabin Class and Travellers</p>
                    <button
    type="button"
    onClick={handleTravellersToggle}
    className="cabin-class-button"
>
    {travellerSummary || "Select Traveller Details"}
</button>


                    {isTravellersDropdownOpen && (
                        <div className="travellers-dropdown">
                          <div style={{ marginBottom: '15px' }}>
    <label style={{ fontWeight: '600' }}>Cabin Class</label>
    <select
        name="cabinClass"
        value={formData.cabinClass}
        onChange={handleChange}
        className="cabin-class-select"
    >
        <option value="Economy">Economy</option>
        <option value="Premium Economy">Premium Economy</option>
        <option value="Business Class">Business Class</option>
        <option value="First Class">First Class</option>
    </select>
</div>


                            <div className="form-group">
                                <label>Adults (Aged 16+)</label>
                                <div className="counter">
                                    <button type="button" onClick={() => setFormData({
                                        ...formData,
                                        travellers: { ...formData.travellers, adults: formData.travellers.adults > 1 ? formData.travellers.adults - 1 : 1 }
                                    })}>-</button>
                                    <input
                                        type="number"
                                        name="adults"
                                        value={formData.travellers.adults}
                                        onChange={handleTravellersChange}
                                        min="1"
                                    />
                                    <button type="button" onClick={() => setFormData({
                                        ...formData,
                                        travellers: { ...formData.travellers, adults: formData.travellers.adults + 1 }
                                    })}>+</button>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Children (Aged 2–15)</label>
                                <div className="counter">
                                    <button type="button" onClick={() => setFormData({
                                        ...formData,
                                        travellers: { ...formData.travellers, children: formData.travellers.children > 0 ? formData.travellers.children - 1 : 0 }
                                    })}>-</button>
                                    <input
                                        type="number"
                                        name="children"
                                        value={formData.travellers.children}
                                        onChange={handleTravellersChange}
                                        min="0"
                                    />
                                    <button type="button" onClick={() => setFormData({
                                        ...formData,
                                        travellers: { ...formData.travellers, children: formData.travellers.children + 1 }
                                    })}>+</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        placeholder="Enter your email"
                    />
                </div>

                <div className="form-group">
                    <label>Phone Number</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        placeholder="Enter your phone number"
                    />
                </div>

                <button type="submit" className="submit-button">Submit</button>
            </form>

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Thank You for Your Submission!</h3>
                        <p>Your information has been successfully received.</p>
                        <button onClick={handleCloseModal} className="close-modal-button">
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Add CSS Styles Below */}
            <style jsx>{`
                .contact-form {
                    max-width: 600px;
                    margin: 20px auto;
                    padding: 30px;
                    border: 1px solid #007bff;
                    border-radius: 8px;
                    background-color: #f7f7f7;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }

                .contact-form h4 {
                    color: #333;
                    font-weight: 600;
                    text-align: center;
                }

                .form-group {
                    margin-bottom: 20px;
                }

                .form-group label {
                    font-weight: 600;
                }

                .form-group input,
                .form-group select {
                    width: 100%;
                    padding: 12px;
                    border-radius: 5px;
                    margin-top: 5px;
                    border: 1px solid #007bff;
                    font-size: 16px;
                    transition: border-color 0.3s;
                }

                .traveller-button {
                    padding: 12px;
                    background-color: #007bff;
                    border: 1px solid #007bff;
                    color: #fff;
                    border-radius: 5px;
                    width: 100%;
                    font-weight: bold;
                    display: block;
                    margin-bottom: 15px;
                    transition: background-color 0.3s;
                }

                .travellers-dropdown {
                    margin-top: 15px;
                    border: 1px solid #007bff;
                    padding: 15px;
                    border-radius: 5px;
                    background-color: #fff;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                }

                .counter {
                    display: flex;
                    align-items: center;
                }

               .counter button {
    padding: 6px;
    background: white; /* Make the background white */
    border: 1px solid #007bff; /* Add border to keep styling consistent */
    border-radius: 5px;
    margin: 0 10px;
    color: #007bff; /* Change text color to blue for contrast */
}

.cabin-class-button {
    padding: 12px;
    background: white; /* White background */
    border: 1px solid #007bff; /* Blue border */
    color: #007bff; /* Blue text color */
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s, color 0.3s; /* Transition effects */
}

.cabin-class-button:hover {
    background-color: #007bff; /* Blue background on hover */
    color: white; /* White text on hover */
}

.cabin-class-select {
    padding: 12px;
    background-color: white; /* White background */
    border: 1px solid #007bff; /* Blue border */
    color: #007bff; /* Blue text color */
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s, color 0.3s; /* Transition effects */
    cursor: pointer;
}

.cabin-class-select:focus {
    border-color: #0056b3; /* Darker blue on focus */
    outline: none; /* Remove the default outline */
}
.cabin-class-select option {
    color: #007bff; /* Blue text for options */
}

.cabin-class-select option:checked {
    background-color: #007bff; /* Blue background for selected option */
    color: white; /* White text for selected option */
}
.cabin-class-select:hover {
    background-color: #f1f1f1; /* Light gray background on hover */
}


                .counter input {
                    width: 50px;
                    text-align: center;
                    padding: 6px;
                    border: 1px solid #007bff;
                    border-radius: 5px;
                }

                .submit-button {
                    width: 100%;
                    padding: 12px;
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 5px;
                    font-weight: bold;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                .submit-button:hover {
                    background-color: #0056b3;
                }

                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.6);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .modal {
                    background-color: #fff;
                    padding: 30px;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    text-align: center;
                    width: 400px;
                }

                .close-modal-button {
                    padding: 10px 20px;
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default ContactFormAirTickets;
