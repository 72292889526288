import React from 'react';
import CustomHomeAbout from '../../components/home-four/CustomHomeAbout';
import CustomCounter from '../../components/counterup/CustomCounter';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import CategoryThreeCustom from '../../components/category/CategoryThreeCustom';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';

export const ExclusiveUniversity = () => {
    const usaBenefits = [
        {
            title: 'World-Class Education',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        },
        {
            title: 'Diverse Academic Programs',
            icon: 'icon-trophy',
            number: 100,
            suffix: '%'
        },
        {
            title: 'Top-Ranked Universities',
            icon: 'icon-Open-book',
            number: 300,
            suffix: '+'
        },
        {
            title: 'Strong Industry Connections',
            icon: 'icon-Bag',
            number: 550,
            suffix: '+'
        }
    ];

    const handleClick = () => {
        window.location.href = 'https://forms.gle/9nJQ2H2ps38fbBPY9';
    };

    const usaItems = [
        {
            title: 'LIM College, New York, USA',
            icon: 'icon-Schoolbag',
            link: 'https://www.limcollege.edu/'
        },
        {
            title: 'Texas A&M University',
            icon: 'icon-student-read',
            link: 'https://www.tamu.edu/'
        },
    ];

    // Defining usaData directly here
    const usaData = {
        title: '',
        mTitle: 'Why Choose Our Exclusive University Partners?',
        desc_1: 'Partnering with top-tier institutions, our exclusive university collaborations provide unparalleled access to high-quality education and unique opportunities tailored for ambitious students. These partnerships enable you to engage with distinguished faculty members, benefit from cutting-edge research, and leverage resources that set you apart in your academic journey. Our partner universities are renowned for their commitment to academic excellence and innovation, offering programs across diverse fields of study that cater to your individual career aspirations.',
        desc_2: 'Our exclusive university partnerships also come with a strong support network, ensuring a smooth transition into university life, especially for international students. From personalized academic guidance to a welcoming campus community, you’ll find a nurturing environment that enhances your educational experience and prepares you for global success. Whether you’re pursuing studies in business, technology, arts, or other disciplines, our partner universities equip you with the knowledge, skills, and international exposure needed to thrive in your future endeavors.',
        subTitle: '',
        funFacts: usaBenefits,
        items: usaItems
    };

    return (
        <>
            <SEO title={`About ${usaData.title}`} />
            <Layout>
                <BreadcrumbOne
                    title="Exclusive University"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Exclusive University"
                />
                
                <CustomHomeAbout
                    sectionFormat={'1'}
                    title={usaData.title}
                    mTitle={usaData.mTitle}
                    desc_1={usaData.desc_1}
                    desc_2={usaData.desc_2}
                    subTitle={usaData.subTitle}
                    imgSrc={'/images/about/about-07/usa1.png'} // Banner Image
                />

                <CustomCounter
                    funFacts={usaData.funFacts}
                />

                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                classes="text-center"
                                slogan=""
                                title="Universities"
                            />
                        </div>
                    </div>

                    <CategoryThreeCustom
                        items={usaData.items}
                    />

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-6 d-flex justify-content-center">
                    <button
                        className="rn-btn edu-btn mb--30"
                        type="button"
                        onClick={handleClick}>
                        <span>Get in touch with us</span>
                    </button>
                </div>
            </Layout>
        </>
    );
}

export default ExclusiveUniversity;
