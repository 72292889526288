import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactFormAirTickets from '../../components/contact/ContactFormAirTickets';

const AirTicket = () => {
    return (
        <>
            <SEO title="Air Tickets" />
            <Layout>
                <BreadcrumbOne
                    title="Air Tickets"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Air Tickets"
                />
                <div className="edu-privacy-policy-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                {/* Display the title first */}
                                <div className="thumbnail mb--50 text-center">
                                    <h2 className="title" style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#333' }}>~ Air Tickets ~</h2>
                                </div>

                                {/* Show the contact form below the title */}
                                <div className="contact-form-section mt--50" style={{ marginTop: '50px', textAlign: 'center', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                    <h4 className="title" style={{ fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '20px' }}>Get in Touch</h4>
                                    <ContactFormAirTickets />
                                </div>

                                {/* Paragraph content below the contact form */}
                                <p style={{ marginTop: '30px', color: '#555', lineHeight: '1.6' }}>
                                    Booking air tickets can be a daunting task, but we make it simple and stress-free. Our platform provides a range of options to suit your travel needs, ensuring you get the best deals available. Whether you’re flying domestically or internationally, we offer competitive prices and a user-friendly booking experience.
                                </p>
                                
                                <h4 className="title" style={{ fontSize: '1.6rem', fontWeight: 'bold', color: '#333', marginTop: '40px' }}>Why Book with Us?</h4>
                                <p style={{ color: '#555', lineHeight: '1.6' }}>
                                    We are committed to providing you with the best deals on air tickets. Our platform allows you to compare prices, choose your preferred airlines, and book your flights with ease. We work with top airlines to bring you exclusive discounts and offers that you won’t find elsewhere.
                                </p>

                                <h4 className="title" style={{ fontSize: '1.6rem', fontWeight: 'bold', color: '#333', marginTop: '40px' }}>Tips for Booking Air Tickets</h4>
                                <ul style={{ textAlign: 'left', margin: '20px auto', maxWidth: '600px', color: '#555', lineHeight: '1.6' }}>
                                    <li><strong>Book Early:</strong> Secure the best fares by booking your tickets well in advance.</li>
                                    <li><strong>Be Flexible:</strong> Consider alternative airports or flight times to find better deals.</li>
                                    <li><strong>Sign Up for Alerts:</strong> Stay informed about special deals and discounts by signing up for our alerts.</li>
                                    <li><strong>Book with Us:</strong> We guarantee the best deals on air tickets, ensuring you save on every booking.</li>
                                </ul>

                                <p style={{ marginTop: '20px', color: '#555', lineHeight: '1.6' }}>
                                    Whether you’re planning a vacation or a business trip, trust us to provide you with the most convenient and cost-effective booking options. Book your air tickets through our platform and experience a hassle-free journey from start to finish.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default AirTicket;
