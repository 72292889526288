import React, { useState } from "react";

// Quiz configuration and questions
const questions = [
  { text: "1.", answer: "a door" },
  { text: "2.", answer: "a picture" },
  { text: "3.", answer: "a computer" },
  { text: "4.", answer: "a board" },
  { text: "5.", answer: "a desk" },
  { text: "6.", answer: "a wall" },
  { text: "7.", answer: "a window" },
  { text: "8.", answer: "a table" },
  { text: "9.", answer: "a chair" },
];

const options = [
  "a wall",
  "a window",
  "a table",
  "a door",
  "a picture",
  "a chair",
  "a computer",
  "a board",
  "a desk",
];

const Unit1CE1 = () => {
  const [userAnswers, setUserAnswers] = useState(Array(9).fill(null));
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [availableOptions, setAvailableOptions] = useState(options);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [startTime] = useState(Date.now());
  const [score, setScore] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const handleDrop = (index) => {
    if (draggedIndex !== null && userAnswers[index] === null) {
      const newAnswers = [...userAnswers];
      newAnswers[index] = availableOptions[draggedIndex];
      setUserAnswers(newAnswers);
      setAvailableOptions((prevOptions) =>
        prevOptions.filter((_, i) => i !== draggedIndex)
      );
      setDraggedIndex(null);
    }
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleSubmit = () => {
    const correctCount = userAnswers.filter(
      (answer, index) => answer === questions[index].answer
    ).length;

    setFeedback(`You got ${correctCount} out of ${questions.length} correct!`);
    setScore(correctCount);
    setTotalTime(((Date.now() - startTime) / 1000).toFixed(2));
    setSubmitted(true);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const returnToOptions = (index) => {
    const answerToReturn = userAnswers[index];
    if (answerToReturn) {
      setAvailableOptions((prevOptions) => [...prevOptions, answerToReturn]);
      const newAnswers = [...userAnswers];
      newAnswers[index] = null;
      setUserAnswers(newAnswers);
    }
  };

  const calculatePercentage = () => {
    return ((score / questions.length) * 100).toFixed(2);
  };

  const restartQuiz = () => {
    setUserAnswers(Array(9).fill(null));
    setSubmitted(false);
    setFeedback("");
    setAvailableOptions(options);
    setDraggedIndex(null);
    setScore(0);
    setTotalTime(0);
    setShowModal(false);
  };

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modal: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      padding: "20px",
      width: "300px",
      textAlign: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    closeButton: {
      marginTop: "15px",
      padding: "8px 12px",
      backgroundColor: "#007BFF",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  return (
    <div style={{ display: "flex", padding: "10px", maxWidth: "1200px", margin: "0 auto" }}>
      {/* Left Side - Video */}
      <div style={{ flex: 1, paddingRight: "10px" }}>
        <video
          src="/videos/Unit01C/classroom-language.mp4"
          autoPlay
          controls
          loop
          muted
          style={{
            width: "100%",
            height: "420px",
            maxHeight: "500px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        />
      </div>

      {/* Right Side - Quiz */}
      <div style={{ flex: 1 }}>
        <h2 style={{ fontSize: "20px", textAlign: "center", margin: "20px 0" }}>
          Drag and Drop Match the words and pictures in the worksheet
        </h2>

        {/* Options Section */}
        <div
          style={{
            border: "1px solid #007BFF",
            borderRadius: "8px",
            padding: "5px",
            marginBottom: "10px",
            overflowY: "auto",
            maxHeight: "150px",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {availableOptions.map((option, index) => (
              <div
                key={index}
                draggable
                onDragStart={() => handleDragStart(index)}
                style={{
                  padding: "8px 12px",
                  margin: "4px",
                  backgroundColor: "#007BFF",
                  color: "#fff",
                  borderRadius: "20px",
                  cursor: "grab",
                  fontSize: "14px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        {/* Questions Section */}
        <div style={{ border: "2px solid #007BFF", borderRadius: "8px", padding: "5px", overflowY: "auto", maxHeight: "300px" }}>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {userAnswers.map((answer, index) => (
              <div
                key={index}
                onDrop={() => handleDrop(index)}
                onDragOver={(e) => e.preventDefault()}
                style={{
                  padding: "5px",
                  margin: "5px",
                  backgroundColor: answer ? "#007BFF" : "#f0f0f0",
                  color: answer ? "#fff" : "#000", // Set text to white if answered, black if not
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                <span style={{ flex: 1 }}>{questions[index].text}</span>
                <span
                  style={{
                    cursor: answer ? "pointer" : "default",
                    border: "0px solid #ccc",
                    padding: "5px 8px",
                    borderRadius: "8px",
                  }}
                  onClick={() => answer && returnToOptions(index)}
                >
                  {answer || "Drop answer here"}
                </span>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={handleSubmit}
          disabled={userAnswers.some((answer) => answer === null)}
          style={{
            width: "100%",
            padding: "8px 12px",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "10px",
            fontSize: "14px",
          }}
        >
          Submit Answers
        </button>

        {/* Modal for displaying results */}
        {showModal && (
          <div style={modalStyles.overlay}>
            <div style={modalStyles.modal}>
              <h3 style={{ color: "#007BFF" }}>Results</h3>
              <p>Correct: {score} / {questions.length}</p>
              <p>Percentage: {calculatePercentage()}%</p>
              <p>Total Time Taken: {totalTime} seconds</p>
              <button onClick={handleCloseModal} style={modalStyles.closeButton}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Unit1CE1;
