import React, { useState } from 'react';

// Exercise data for Exercise B
const exerciseB = [
  { text: "Mark and Simon are friends. ___________ are in class 2.", options: ["They", "Their"], answer: "They" },
  { text: "She's a new student. ___________ name's Ipek.", options: ["She", "Her"], answer: "Her" },
  { text: "Is ___________ teacher British?", options: ["they", "their"], answer: "their" },
  { text: "My name's Soraya. I'm in ___________ class.", options: ["you", "your"], answer: "your" },
  { text: "Is ___________ German?", options: ["she", "her"], answer: "she" },
  { text: "Peter is a teacher. ___________ is from Ireland.", options: ["He", "His"], answer: "He" },
  { text: "What's ___________ name?", options: ["he", "his"], answer: "his" },
  { text: "Where are ___________ friends from?", options: ["you", "your"], answer: "your" },
  { text: "We're French. ___________ names are Marc and Jacques.", options: ["We", "Our"], answer: "Our" },
  { text: "I'm Karen. ___________ surname is White.", options: ["I", "My"], answer: "My" },
  { text: "___________ is from Barcelona.", options: ["She", "Her"], answer: "She" },
];

const ExerciseB = () => {
  const [selectedOptionsB, setSelectedOptionsB] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);  // Modal visibility state
  const [score, setScore] = useState(0);  // Score state

  // Handle selecting an option for Exercise B
  const handleSelectOptionB = (index, selectedOption) => {
    setSelectedOptionsB({
      ...selectedOptionsB,
      [index]: selectedOption,
    });
  };

  // Handle submission for Exercise B
  const handleSubmitB = () => {
    let correctCount = 0;

    // Check answers for Exercise B
    exerciseB.forEach((item, index) => {
      if (selectedOptionsB[index] === item.answer) {
        correctCount++;
      }
    });

    setScore(correctCount);
    setSubmitted(true);
    setShowModal(true);  // Show the modal after submission
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Modal styles
  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      width: '300px',
    },
    closeButton: {
      marginTop: '15px',
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ width: '50%', border: '1px solid #ddd', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
        <h4 style={{ fontSize: '1.4rem', marginBottom: '15px', textAlign: 'center' }}>b. Select the correct word</h4>

        {/* The exercise questions */}
        {exerciseB.map((item, index) => (
          <div key={index} style={{ marginBottom: '15px', textAlign: 'left' }}>
            <span>{item.text.split("___________")[0]}</span>
            {item.options.map((option, i) => (
              <button
                key={i}
                onClick={() => handleSelectOptionB(index, option)}
                style={{
                  margin: '0 5px',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  backgroundColor:
                    submitted
                      ? selectedOptionsB[index] === option
                        ? option === item.answer
                          ? 'lightgreen'
                          : 'lightcoral'
                        : '#f8f9fa'
                      : '#f8f9fa',  // Default color before submission
                  color:
                    selectedOptionsB[index] === option
                      ? option === item.answer
                        ? 'blue'
                        : 'blue'
                      : 'grey',
                  border: '1px solid #ced4da',
                  cursor: 'pointer',
                }}
              >
                {option}
              </button>
            ))}
            <span>{item.text.split("___________")[1]}</span>
          </div>
        ))}

        <button
          onClick={handleSubmitB}
          style={{
            marginTop: '30px',
            padding: '10px 20px',
            borderRadius: '8px',
            backgroundColor: '#28A745',
            color: '007BFF',
            border: 'none',
            cursor: 'pointer',
            fontSize: '1.3rem',
            display: 'block',
            width: '100%',
          }}
        >
          Submit Answers
        </button>
      </div>

      {/* Modal for displaying results */}
      {showModal && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.modal}>
            <h3 style={{ color: "#007BFF" }}>Results</h3>
            <p>Correct: {score} / {exerciseB.length}</p>
            <p>Percentage: {Math.round((score / exerciseB.length) * 100)}%</p>
            <button onClick={handleCloseModal} style={modalStyles.closeButton}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExerciseB;
