import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import Unit1CE1 from "../../components/course/Unit1CE1"; // Import your exercise components
import Unit1CE2 from "../../components/course/Unit1CE2";
import Unit1CE3 from "../../components/course/Unit1CE3";
import Unit1CE4 from "../../components/course/Unit1CE4";
import Unit1CE5 from "../../components/course/Unit1CE5";
import Unit1CE6 from "../../components/course/Unit1CE6";
import Unit1CE7 from "../../components/course/Unit1CE7";
import Unit1CE8 from "../../components/course/Unit1CE8";
import Unit1CE9 from "../../components/course/Unit1CE9";
import Unit1CE10 from "../../components/course/Unit1CE10";
import Unit1CE11 from "../../components/course/Unit1CE11";
import Unit1CE12 from "../../components/course/Unit1CE12";
import Unit1CE13 from "../../components/course/Unit1CE13";
import Unit1CE14 from "../../components/course/Unit1CE14";
import Unit1CE15 from "../../components/course/Unit1CE15";
import Unit1CE16 from "../../components/course/Unit1CE16";
import Unit1CE17 from "../../components/course/Unit1CE17";
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const Unit1CCourse = () => {
  const getSavedState = () => {
    const savedState = localStorage.getItem('courseState');
    return savedState ? JSON.parse(savedState) : {
      currentModuleIndex: 0,
      currentLessonIndex: 0,
      currentVideoIndex: 0,
      isVideoCompleted: false,
      isIntroVideo: true,
      openCategory: null,
      isCourseStarted: false,
    };
  };
 const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isVideoCompleted, setIsVideoCompleted] = useState(false);
  const [openCategory, setOpenCategory] = useState(null);
  const [isCourseStarted, setIsCourseStarted] = useState(false);
  const [isIntroVideo, setIsIntroVideo] = useState(true);
  const videoRef = useRef(null);

  const CurriculumTabContent = [
      {
        heading: "Unit 1C",
        intro: { title: "Sharing Personal Information", videoUrls: ["/videos/Unit01C/FETC-Ep-01C_slide-1.mp4"] },

        categories: [
          {
            categoryTitle: "Vocabulary",
            lessons: [
              { title: "Unit 1C.1 Cassroom Language", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-2.mp4"] },
              { title: "Introduction", videoUrls: ["/videos/Unit01C/FETC-Unit-1C-intro.mp4"] },
              { title: "Unit 1C. 1a", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-3.mp4", "/videos/Unit01C/FETC-Ep-01-C_slide-5.mp4"] },
              { title: "EE.a - Match the words and pictures", videoUrls: [""], quiz: <Unit1CE1 /> },
             /* { title: "With a partner, ask about the things in your classroom using the questions", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-6.mp4"] },*/
              { title: "Unit 1C 1b", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-8-10.mp4"] },
              { title: "EE.b - With a partner, ask about the things in your classroom using the questions", videoUrls: [""], quiz: <Unit1CE2 /> },
              { title: "Unit 1C. 1c", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-11-12.mp4"] },
              { title: "EE.c - Match the sentences", videoUrls: [""], quiz: <Unit1CE3 /> },
            //  { title: "Vocabulary Bank", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-15-17.mp4"] },
              { title: "EE.d - Match the Phrases with the pictures.", videoUrls: [""], quiz: <Unit1CE4 /> },
             // { title: "Unit 1C. 1b", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-21-22.mp4"] },
              { title: "Follow instructions and do the actions.", videoUrls: ["/videos/Unit01C/FETC-Ep01-C_slide-22-b.mp4"] },
            ],
          },
          {
            categoryTitle: "Pronunciation",
            lessons: [
              { title: "Unit 1C.2 - Phonetic Sounds and Alphabets", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-23-24.mp4"] },
              { title: "Unit 1C- 2a Listen and repeat.", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-25-26.mp4"] },
              { title: "EE.a - Listen to the words and sounds and then repeat", videoUrls: [""], quiz: <Unit1CE5 /> },
              { title: "English Alphabets", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-27.mp4"] },
              { title: "Unit 1C- 2b Listen and repeat the alphabet", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-28-29.mp4"] },
              { title: "EE.b", videoUrls: [""], quiz: <Unit1CE6 /> },
              { title: "Unit 1C- 2c Complete the alphabet chart with the alphabets you hear", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-30-31.mp4"] },
              { title: "EE.c Alphabet chart", videoUrls: [""], quiz: <Unit1CE7 /> },
              { title: "Unit 1C- 2c", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-33-34.mp4"]},
              { title: "EE.d", videoUrls: [""], quiz: <Unit1CE8 /> },
              { title: "Unit 1C- 2d", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-37.mp4"] },
              { title: "Practice saying the phrases with me", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-38-A.mp4", "/videos/Unit01C/FETC-Ep-01-C_slide-38-B.mp4"] },
            ],
          },
          {
            categoryTitle: "Listening & Speaking",
            lessons: [
          { title: "Introduction", videoUrls: ["/videos/Unit01C/FETC-Unit-1C-listening-speaking-intro.mp4"] },
              { title: "Unit 1C.3 Listening & Speaking", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-39.mp4"] },
           /*   { title: "Play the Roleplay", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-40.mp4"] },*/
              { title: "Roleplay", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-41.mp4"] },
              { title: "Unit 1C.3a", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-42-43.mp4"] },
              { title: "EE.a Complete the forms", videoUrls: [""], quiz: <Unit1CE9 /> },
             /* { title: "Unit 1C.3a Complete Mohit's questions to Mega.", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-45-46.mp4"] },
              { title: "EE.b Mohit's questions to Mega", videoUrls: [""], quiz: <Unit1CE00 /> },*/
              { title: "Unit 1C-3b", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-47-48.mp4"] },
              { title: "EE.b Practice the sentence stress", videoUrls: [""], quiz: <Unit1CE10 /> },
            ],
          },
          {
            categoryTitle: "Grammar",
            lessons: [
              { title: "Unit 1C 4", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-49.mp4"] },
              { title: "Introduction", videoUrls: ["/videos/Unit01C/FETC-Unit-1C-grammar-intro.mp4"] },
              { title: "Unit 1C.4a	Complete the following with possessive adjectives", videoUrls: [""], quiz: <Unit1CE11 /> },
              { title: "Singular and plural", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-52.mp4"] },
              { title: "Unit 1C.4b A- learn grammar", videoUrls: [""], quiz: <Unit1CE12 /> },
              { title: "Unit 1C.4b B- learn grammar", videoUrls: [""], quiz: <Unit1CE13 /> },
            /*  { title: "Answers", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-54-B.mp4"] },*/
             /* { title: "Main exercise 1C", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-55.mp4"] },*/
            ],
          },
          {
            categoryTitle: "Writing",
            lessons: [
              { title: "Unit 1C.5 Writing", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-56.mp4", "/videos/Unit01C/FETC-Ep-01-C_slide-57.mp4"] },
              { title: "Unit 1C.5a Focus on the Capital Rules", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-58.mp4"] },
              { title: "Capitalization Rules", videoUrls: [""], quiz: <Unit1CE14 /> },
              { title: "EE.a Capitalization Rules", videoUrls: [""], quiz: <Unit1CE15 /> },
              { title: "Unit 1C.5b Complete the outline form with your information", videoUrls: [""], quiz: <Unit1CE16 /> },
              { title: "Unit 1C.5c", videoUrls: ["/videos/Unit01C/FETC-Ep-01-C_slide-60.mp4"] },
              { title: "EE.b", videoUrls: [""], quiz: <Unit1CE17 /> },
            ],
          },
        ],
      },
    ];
    

    const currentLesson = CurriculumTabContent[currentModuleIndex].categories[openCategory !== null ? openCategory : 0].lessons[currentLessonIndex];
    const currentIntroVideoUrl = CurriculumTabContent[currentModuleIndex].intro.videoUrls[0];
  const currentVideoUrl = currentLesson.videoUrls[currentVideoIndex];

  useEffect(() => {
    if (isIntroVideo && videoRef.current) {
      videoRef.current.src = currentIntroVideoUrl; // Set intro video source
      videoRef.current.play();
    } else if (!isIntroVideo && currentLesson && currentLesson.videoUrls.length > 0 && videoRef.current) {
      if (!isVideoCompleted) {
        videoRef.current.src = currentVideoUrl;
        videoRef.current.play();
      }
    } else {
      console.log("No video available for this lesson.");
    }
  }, [isIntroVideo, currentLesson, currentVideoUrl, currentIntroVideoUrl, isVideoCompleted]);
  
  
  
  

  const handleVideoEnd = () => {
    if (isIntroVideo) {
      setIsIntroVideo(false); // Transition from intro to lesson video
    } else {
      if (currentVideoIndex < currentLesson.videoUrls.length - 1) {
        setCurrentVideoIndex(currentVideoIndex + 1); // Move to the next video
      } else {
        setIsVideoCompleted(true); // Mark video as completed
        videoRef.current.pause(); // Pause video at the end
        videoRef.current.currentTime = 0; // Reset video to the beginning
      }
    }
  };
  
  

  const toggleCategory = (index) => {
    setOpenCategory(openCategory === index ? null : index);
  };

  const selectLesson = (lesson, lessonIndex) => {
    const currentCategoryIndex = CurriculumTabContent[currentModuleIndex].categories.findIndex(category =>
        category.lessons.includes(lesson)
    );
    
    setOpenCategory(currentCategoryIndex); // Open the category of the selected lesson
    setCurrentLessonIndex(lessonIndex); // Track the selected lesson within the current category
    setCurrentVideoIndex(0); // Start from the first video in the selected lesson
    setIsVideoCompleted(false); // Reset video completion
    setIsIntroVideo(false); // Ensure we start with the lesson video, not the intro
  };
  


  const goToNextLesson = () => {
    const currentCategoryIndex = openCategory !== null ? openCategory : 0;
    const currentCategory = CurriculumTabContent[currentModuleIndex].categories[currentCategoryIndex];

    // Check if we're still in the introductory phase
    if (isIntroVideo) {
        // If the intro is still playing, we should move to the first lesson
        setIsIntroVideo(false); // Set intro to false to indicate that we're moving into lessons
        setCurrentLessonIndex(0); // Go to the first lesson
        setCurrentVideoIndex(0); // Reset the video index for the first lesson
    } else {
        // Check if there is a next lesson in the current category
        if (currentLessonIndex < currentCategory.lessons.length - 1) {
            // Move to the next lesson within the same category
            console.log("Moving to next lesson in the current category.");
            setCurrentLessonIndex(currentLessonIndex + 1);
            setCurrentVideoIndex(0);
            setIsVideoCompleted(false);
            setIsIntroVideo(false);
        } else {
            // If at the end of the current category, try to move to the next category
            const nextCategoryIndex = currentCategoryIndex + 1;

            if (nextCategoryIndex < CurriculumTabContent[currentModuleIndex].categories.length) {
                console.log("Moving to the first lesson of the next category.");
                setOpenCategory(nextCategoryIndex); // Open the next category
                setCurrentLessonIndex(0); // Set to the first lesson of the new category
                setCurrentVideoIndex(0); // Reset video index for the new lesson
                setIsVideoCompleted(false);
                setIsIntroVideo(false);
            } else {
                console.log("No more categories to navigate to.");
            }
        }
    }
};


  const goToPreviousLesson = () => {
    const currentCategoryIndex = openCategory !== null ? openCategory : 0;
    const currentCategory = CurriculumTabContent[currentModuleIndex].categories[currentCategoryIndex];
  
    // Check if there is a previous lesson in the current category
    if (currentLessonIndex > 0) {
      // Move to the previous lesson within the same category
      console.log("Moving to previous lesson in the current category.");
      setCurrentLessonIndex(currentLessonIndex - 1);
      setCurrentVideoIndex(0);
      setIsVideoCompleted(false);
      setIsIntroVideo(false);
    } else {
      // If at the beginning of the current category, move to the previous category
      const previousCategoryIndex = currentCategoryIndex - 1;
  
      if (previousCategoryIndex >= 0) {
        console.log("Moving to the last lesson of the previous category.");
        setOpenCategory(previousCategoryIndex); // Open the previous category
        const previousCategory = CurriculumTabContent[currentModuleIndex].categories[previousCategoryIndex];
        setCurrentLessonIndex(previousCategory.lessons.length - 1); // Set to the last lesson of the new category
        setCurrentVideoIndex(0); // Reset video index for the new lesson
        setIsVideoCompleted(false);
        setIsIntroVideo(false);
      } else {
        console.log("No previous categories to navigate to.");
      }
    }
  };
  
  

  const startCourse = () => {
    setIsCourseStarted(true); // Set the course as started
    setCurrentLessonIndex(0); // Start with the first lesson (Classroom Language)
    setCurrentVideoIndex(0); // Reset video index to 0
    setIsVideoCompleted(false); // Reset video completion state
    setIsIntroVideo(true); // Start with the intro video
  };

  return (
    <>
      <SEO title="Unit 1 C" />
      <Layout>
        <BreadcrumbOne title="Unit 1 C" rootUrl="/" parentUrl="Home" subUrl="Elementary" currentUrl="Unit 1 C" />
        <div style={{ display: "flex", flexDirection: window.innerWidth < 768 ? "column" : "row", padding: "20px" }}>
  <div style={{ flex: window.innerWidth < 768 ? "1" : "0.3", paddingRight: "20px", paddingLeft: "30px", borderRight: window.innerWidth >= 768 ? "1px solid #ddd" : "none" }}>
    <h2 style={{ fontSize: "20px", marginBottom: "20px", color: "#333" }}>Course Modules</h2>
    {CurriculumTabContent.map((module, moduleIndex) => (
      <div key={moduleIndex} style={{ marginBottom: "20px" }}>
        <h3 style={{
          fontSize: "18px",
          fontWeight: "bold",
          cursor: "pointer",
          color: moduleIndex === currentModuleIndex ? "#007BFF" : "#333",
          backgroundColor: moduleIndex === currentModuleIndex ? "#e0f3ff" : "transparent",
          borderLeft: moduleIndex === currentModuleIndex ? "4px solid #007BFF" : "4px solid transparent",
          padding: "10px",
          marginBottom: "10px",
          transition: "background-color 0.3s, color 0.3s",
          borderRadius: "5px",
        }}>
          {module.heading}
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "15px", margin: "0" }}>
          {module.categories.map((category, categoryIndex) => (
            <div key={categoryIndex} style={{ marginBottom: "10px" }}>
              <h4 onClick={() => toggleCategory(categoryIndex)} style={{
                fontSize: "16px",
                fontWeight: "bold",
                cursor: "pointer",
                color: openCategory === categoryIndex ? "#007BFF" : "#333",
                padding: "5px 0",
                transition: "color 0.3s",
              }}>
                {category.categoryTitle}
              </h4>
              {openCategory === categoryIndex && (
                <ul style={{ listStyleType: "none", paddingLeft: "15px", margin: "0" }}>
                  {category.lessons.map((lesson, lessonIndex) => (
                   <li
                   key={lessonIndex}
                   style={{
                     marginBottom: "5px",
                     cursor: "pointer",
                     color: currentLessonIndex === lessonIndex && openCategory === categoryIndex ? "#007BFF" : "#333",
                     backgroundColor: currentLessonIndex === lessonIndex && openCategory === categoryIndex ? "#e0f3ff" : "transparent",
                     padding: "5px",
                     borderRadius: "3px"
                   }}
                   onClick={() => selectLesson(lesson, lessonIndex)}
                 >
                   {lesson.title}
                 </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </ul>
      </div>
    ))}
  </div>

  <div style={{ flex: window.innerWidth < 768 ? "1" : "0.7", paddingLeft: "20px" }}>
    {isCourseStarted && (
      <>
        {isIntroVideo ? (
          <div>
            <h3 style={{ textAlign: "center", color: "#333", marginBottom: "10px" }}>Introductory Video</h3>
            <video
              ref={videoRef}
              controls
              onEnded={handleVideoEnd}
              controlsList="nodownload"
              style={{
                width: "100%",
                marginBottom: "10px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
        ) : (
          <div>
            <h3 style={{ textAlign: "center", color: "#333", marginBottom: "10px" }}>{currentLesson.title}</h3>
            {currentLesson.videoUrls.length > 0 && currentLesson.videoUrls[0] ? (
              <video
                ref={videoRef}
                controls
                onEnded={handleVideoEnd}
                controlsList="nodownload"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              <div style={{ marginBottom: "10px", padding: "10px", border: "1px solid #ddd", borderRadius: "8px" }}>
              </div>
            )}
            {isVideoCompleted && currentLesson.quiz && (
              <div style={{ marginTop: "20px" }}>
                <h3 style={{ color: "#333" }}>Quiz</h3>
                {currentLesson.quiz} {/* This should render the quiz component */}
              </div>
            )}
            {/* If there is no video, show the exercise directly */}
            {!currentLesson.videoUrls.length || !currentLesson.videoUrls[0] ? (
              currentLesson.quiz
            ) : null}
          </div>
        )}
        
        {/* Navigation Buttons */}
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", flexDirection: window.innerWidth < 768 ? "column" : "row" }}>
          <Button
            variant="outline-primary"
            onClick={goToPreviousLesson}
            disabled={currentLessonIndex === 0 && openCategory === 0}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              borderRadius: "5px",
              marginRight: window.innerWidth < 768 ? "0" : "10px",
              marginBottom: window.innerWidth < 768 ? "10px" : "0", // Add margin for mobile
            }}
          >
            Previous Lesson
          </Button>

          <Button
            variant="outline-primary"
            onClick={goToNextLesson}
            disabled={
              (currentLessonIndex === CurriculumTabContent[currentModuleIndex].categories[openCategory !== null ? openCategory : 0].lessons.length - 1) &&
              (openCategory === CurriculumTabContent[currentModuleIndex].categories.length - 1)
            }
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              borderRadius: "5px",
              marginLeft: window.innerWidth < 768 ? "0" : "10px",
            }}
          >
            Next Lesson
          </Button>
        </div>
      </>
    )}
{/* If course is not started, show the circular Start Course button */}
{!isCourseStarted && (
  <div style={{ 
    display: "flex", 
    justifyContent: "center", // Align the button to the right
    paddingRight: "100px", // Add some space on the right
    marginTop: "50px" // Space above the button
  }}>
    <Button
      onClick={startCourse}
      style={{
        padding: "20px",
        fontSize: "16px",
        fontWeight: "bold",
        borderRadius: "50%", // Circular button
        width: "100px", // Set fixed width for the circle
        height: "100px", // Set fixed height for the circle
        display: "flex", // Flex to center the text inside the button
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center", // Ensure text is centered
      }}
    >
      Start Course
      </Button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  </>
    
  );
};

export default Unit1CCourse;
